import React, { Fragment, useEffect, useState,useRef } from 'react';
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import {logout,isAuthenticated} from '../../actions/auth';
import {WEB_URL} from '../../constants';
import {userList,updateStatus,deleteUser} from '../../actions/AdminApi';

import { useAlert } from 'react-alert';




const UserListing = () =>{

   const alert = useAlert()

    const {user} =  isAuthenticated();

      const [users , setUsers] =  useState([])
      const [error, setError] = useState(false)
      const token = user.token;

      const init = () => {
         const Userdata = {
            token
          };

         
         userList(Userdata,(res) => {
            if(res.data.status){
               setUsers(res.data.data)
            }else{
               setError(res.data.data)
            }
          });
      };

      const changeStatus = (userobj) =>{

         var userid = userobj._id;
        var status = (userobj.status==1) ? 3:1;

         const newUser = {
            userid,
            status,
            token
          };
    
          //
    
          updateStatus(newUser, (res) => {
            if (res.data.success) {
               alert.success('Erfolgreich aktualisiert');
               init()
            } else{
               alert.error('Da ist was schief gelaufen');

            }
          });


      }

      const deleteusers = (userobj) =>{

         var userid = userobj._id;

         const newUser = {
            userid,
            token 
          };
    
          //
    
          deleteUser(newUser, (res) => {
            if (res.data.success) {
               alert.success('User gelöscht');

               init()
            } else{
               alert.error('Da ist was schief gelaufen');
            }
          });


      }

      const mounted = useRef();
     
      useEffect(() => {
        

         if (!mounted.current) {
            init()
            mounted.current = true;
          } else {
            init()
          }

          if(window.innerWidth<480){
               document.getElementById("sidebarToggleTop").click();
             
            }
      },[])

     


 return (
    
    <Fragment>
             <main className="admin_dashboard_panel">

      <div id="wrapper" >
         <Sidebar/>
         <div id="content-wrapper" className="d-flex flex-column" style={{padding: "0px"}}>
            <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar static-top ">
                  <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"admin_assets/img/logo_sharify.svg"} className="mobile-logo" />
               </nav>
               <div className="ctn_text_warp">
                  <div className="box_top_bar d-flex align-items-center justify-content-between">
                     <div className="search d-flex align-items-center">
                        <p className="mb-0 ml-2">Userübersicht</p>
                     </div>
                     <div className="ctn_logout float-right">
                     <Link to="" onClick={() => logout()}>Abmelden</Link>
                     </div>
                  </div>
                  <div className="container-fluid p-0">
                     <div className="row p-4">
                        <div className="col-md-12">
                           <div className="heading_text_ctn">
                              <h2 className="mr-5 pr-5">User</h2>
                           </div>
                        </div>
                        <div className="col-md-12 mt-3 admin_user">
                           <table className="rwd-table shadow">
                              <tbody>
                                 <tr>
                                    <th>USER</th>
                                    <th>EMAIL</th>
                                    <th>STATUS</th>
                                    <th></th>
                                    <th>ACTION</th>
                                 </tr>
                                 
                                 {users.map((user,i)=>(
                                    
                                     <tr key={i}>
                                     <td data-th="USER">{user.username}</td>
                                     <td data-th="USER">{user.email}</td>
                                 <td data-th="STATUS"><a href="#" onClick={() => changeStatus(user)} >{(user.status===1) ? 'Aktiviert':'Deaktiviert' }</a></td>
                                     <td data-th=""></td>
                                     <td data-th=""><a href="#" onClick={() => deleteusers(user)} ><img src={WEB_URL+"admin_assets/img/svg/Icon-delete.svg"} /></a></td>
                                    </tr>
                                 ))}
                                
                               
                              </tbody>
                           </table>
                        </div>
                        {/* <div className="col-md-12 text-right mt-5">
                           <div className="content_detail__pagination cdp" actpage="1">
                              <a href="#!-1" className="cdp_i"><img src={WEB_URL+"admin_assets/img/svg/right-icon.svg"}/></a>
                              <a href="#!1" className="cdp_i active">1</a>
                              <a href="#!2" className="cdp_i">2</a>
                              <a href="#!3" className="cdp_i">3</a>
                              <a href="#!4" className="cdp_i">4</a>
                              <a href="#!5" className="cdp_i">5</a>
                              <a href="#!+1" className="cdp_i"><img src={WEB_URL+"admin_assets/img/svg/left-icon.svg"}/></a>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
      </main>   
      
  
      <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
      </a>
      </Fragment>
 )
}

export default UserListing;