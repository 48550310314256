import React, { Fragment, useEffect, useState,useRef } from 'react';
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import {logout,isAuthenticated} from '../../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {mediaList,updateStatusMedia,deleteMedia,getPayoutRequestsAdmin,updateStatusPayout} from '../../actions/AdminApi';
import DataTable from 'react-data-table-component';
import { useAlert } from 'react-alert';
import  Moment from 'moment';






const PaymentRequest = () =>{
   const alert = useAlert()

  

    const {user} =  isAuthenticated();

      const [payout , setPayout] =  useState([])
      const [error, setError] = useState(false)
      const token = user.token;

      const init = () => {
         const Userdata = {
            token
          };

         
          getPayoutRequestsAdmin(Userdata,(res) => {
            if(res.data.status){
               setPayout(res.data.data)
            }else{
            }
          });


         


      };

      const data = payout;
      const columns = [
        {
          name: 'DATUM',
          selector: 'createdOn',
          cell: row =>Moment(row.createdOn).format('YYYY-MM-DD'),
        },
        {
          name: 'BETRAG',
          selector: 'amount',
          sortable: true,
        cell: row =><div>{row.amount} Euro</div>,
        },
        {
            name: 'USER',
            selector: 'useremail',
            sortable: true,
            cell: row =>row.userbank.user.email,
        },
        {
         name: 'ADRESSE',
         selector: 'status',
         sortable: true,
         cell: row =><div data-th="Address">{row.userbank.company}<br/>{row.userbank.company_address}<br/>{row.userbank.city}<br/>{row.userbank.country}<br/>{row.userbank.postal_code}</div>,

       },
       {
        name: 'BANKVERBINDUNG',
        selector: 'bank',
        cell: row =><div data-th="Address">{row.userbank.account_holder}<br/>BIC:{row.userbank.bic}<br/>IBAN{row.userbank.iban}</div>,
    },
      {
        name: 'STEUERNUMMER',
        selector: 'tax',
      cell: row =><div data-th="tax">{row.userbank.tax_number}</div>,
      },
      {
        name: 'GUTSCHIFT',
        selector: 'mediatype',
        sortable: true,
         cell: row =><div data-th="tax"> <a href={"/creditNote/"+row._id} target="_blank"><i class="fa fa-download" aria-hidden="true"></i></a></div>,  
      },
       {
         name: 'BEZAHLT?',
         selector: 'action',
         cell: row => <a href="#" onClick={() => changeStatus(row)} >{(row.withdrawStatus==1) ? (<button class="btn btn-success">Bezahlt</button>):(<button class="btn btn-danger">Unbezahlt</button>) }</a>,
       },
      ];

      const changeStatus = (obj) =>{

         var reqid = obj._id;
        var status = (obj.status==1) ? 2:1;

         const newUser = {
            reqid,
            status,
            token
          };
    
          //
    
          updateStatusPayout(newUser, (res) => {
            if (res.data.success) {
               alert.success('Erfolgreich aktualisiert');

               init()
            }else{
               alert.error('Da ist was schief gelaufen');

            } 
          });


      }

  

      const mounted = useRef();
     
      useEffect(() => {
        
        init()

        if(window.innerWidth<480){
            document.getElementById("sidebarToggleTop").click();
      
          
         }
        
      },[])

     


 return (
    
    <Fragment>
             <main className="admin_dashboard_panel">

      <div id="wrapper"  >
         <Sidebar/>
         <div id="content-wrapper" className="d-flex flex-column" style={{padding: "0px"}}>
            <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar static-top ">
                  <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"admin_assets/img/logo_sharify.svg"} className="mobile-logo" />
               </nav>
               <div className="ctn_text_warp">
                  <div className="box_top_bar d-flex align-items-center justify-content-between">
                     <div className="search d-flex align-items-center">
                        <p className="mb-0 ml-2">Auszahlungsübersicht</p>
                     </div>
                     <div className="ctn_logout float-right">
                     <Link to="" onClick={() => logout()}>Abmelden</Link>
                     </div>
                  </div>
                  <div className="container-fluid p-0">
                     <div className="row p-4">
                        <div className="col-md-12">
                           <div className="heading_text_ctn">
                              <h2 className="mr-5 pr-5">Auszahlungen</h2>
                           </div>
                        </div>
                        <div className="col-md-12 mt-3 admin_user">
                        <DataTable
                           title=""
                           columns={columns}
                           data={data}
                           pagination={true}
                           className="rwd-table shadow"
                           />
                     
                      
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
         </div>
      </main>
  
      <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
      </a>
      </Fragment>
 )
}

export default PaymentRequest;