import {API_URL} from '../constants';
import {isAuthenticated} from './auth';
const axios = require('axios');






const server_url = API_URL;


 

//  if(isAuthenticated()){
//     const {user} = isAuthenticated();
    
//      token = user.token;

//      console.log(token);
//  }




// get user list 

export const userList = (data,callback) =>{

    const {token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getUsers',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}

// media list

export const mediaList = (data,callback) =>{

    const {token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getAllMedia',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


export const mediaListByCampign = (data,callback) =>{

    const {token,id} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getCampaignByid/'+id,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}

export const generateCreditNote = (data,callback) =>{

    const {token,id} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/generateCreditNote/'+id,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}



export const getMediaUser = (data,callback) =>{

    const {token,id,type} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getMediaUser/'+id+"/"+type,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


// campign list 

export const campiganArchivedList = (data,callback) =>{

    const {token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getArchivedCampaigns',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


export const campiganList = (data,callback) =>{

    const {token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getActivewCampaigns',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}

export const usercampiganList = (data,callback) =>{

    const {token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }
  
  
   axios.get(server_url+'api/getuserCampaigns',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}




// update user status

export const updateStatus = (data,callback) =>{
    const {userid,status,token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({userid,status});
   
    axios.post(server_url+'api/updateUserStatus',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}

export const updateStatusCampign = (data,callback) =>{
    const {campid,token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({campid});
   
    axios.post(server_url+'api/updateStatusCampign',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}

export const updateStatusPayout = (data,callback) =>{
    const {reqid,token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({reqid});
   
    axios.post(server_url+'api/updateStatusPayout',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}







export const updateStatusMedia = (data,callback) =>{
    const {mediaid,status,token} = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({mediaid,status});
   
    axios.post(server_url+'api/mediaStatusChange',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const deleteUser = (data,callback) =>{
    const {userid,token} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({userid});
   
    axios.post(server_url+'api/deleteUser',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}
// delete media 

export const deleteMedia = (data,callback) =>{
    const {mediaid,token} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({mediaid});
   
    axios.post(server_url+'api/deleteMedia',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}

export const ArchivedCampiagn = (data,callback) =>{
    const {campid,token} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({campid});
   
    axios.post(server_url+'api/deactive-Campign',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}



export const upload = (data,callback) =>{
    const {formDatas,token,attName} = data;
    const config = {
        headers:{
            'Content-Type': 'multipart/form-data',
            'Authorization': `token ${token}`
        }
     }
    axios.post(server_url+'api/'+attName,formDatas,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const uploadUpdate = (data,callback) =>{
    const {formDatas,token,attName,camid} = data;
    const config = {
        headers:{
            'Content-Type': 'multipart/form-data',
            'Authorization': `token ${token}`
        }
     }
    axios.post(server_url+'api/updateUpload/'+attName+'/'+camid,formDatas,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const addCamgian = (data,callback) =>{
    const { 
        title,
        banner,
        profile,
        description,
        briefing,
        linkAndroid,
        linkIos,
        max_availble_downloads,
        user_per_download,
        sharify_get_download,
        campaigns_from_date,
        campaigns_till_date,
        stories,
        feeds,
        token
    } = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({ title,
        banner,
        profile,
        description,
        briefing,
        linkAndroid,
        linkIos,
        max_availble_downloads,
        user_per_download,
        sharify_get_download,
        campaigns_from_date,
        campaigns_till_date,
        stories,
        feeds});
   
    axios.post(server_url+'api/addCampaign',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const updateCamgian = (data,callback) =>{
    const { 
        id,
        title,
        banner,
        profile,
        linkAndroid,
        linkIos,
        description,
        briefing,
        max_availble_downloads,
        user_per_download,
        sharify_get_download,
        campaigns_from_date,
        campaigns_till_date,
        stories,
        feeds,
        token
    } = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({
        id,
         title,
        banner,
        profile,
        description,
        linkAndroid,
        linkIos,
        briefing,
        max_availble_downloads,
        user_per_download,
        sharify_get_download,
        campaigns_from_date,
        campaigns_till_date,
        stories,
        feeds});
   
    axios.post(server_url+'api/edit_campaign',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const linkClicking = (data,callback) =>{
  


    const config = {
        headers:{
            'Content-type':'application/json',
        }
     }


    const body = JSON.stringify(data);
   
    axios.post(server_url+'api/linkClicking',body,config)
    .then(res =>{
        

        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


//  rahul


export const saveBankDetails = (data,callback) =>{
    
    const { 
        company,
        company_address,
        postal_code,
        city,
        country,
        account_holder,
        iban,
        bic,
        token,
        userid,
        additional_optional,
        tax_number,
        tax_information_person
    } = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({
        company,
        company_address,
        postal_code,
        city,
        country,
        account_holder,
        iban,
        bic,
        userid,
        additional_optional,
        tax_number,
        tax_information_person
    });
    //console.log(body.userid);
   //console.log(body);return false;
    axios.post(server_url+'api/add-bank-detail',body,config)
    .then(res =>{
        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}


export const disLink = (data,callback) =>{
    
    const { 
        campid,
        userid,
        token
    } = data;


    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({
        campid,
        userid
       
    });
    //console.log(body.userid);
   //console.log(body);return false;
    axios.post(server_url+'api/disLink',body,config)
    .then(res =>{
        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}




export const getBankDetails = (data,callback) =>{
    
   //console.log(data.userId);return false;
    const { 
        userId,
        token
    } = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }

    const body = JSON.stringify({userId});
    //console.log(token);return false;
   axios.get(server_url+'api/getBankDetails/'+userId,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}

export const sendPayoutRequest = (data,callback) =>{
    //console.log(data);return false;
    const { 
        token,
        userid
    } = data;

//console.log('In api calll');return false;
    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
     }


    const body = JSON.stringify({
        userid
    });
    //console.log(body.userid);
   //console.log(body);return false;
    axios.post(server_url+'api/sendPayoutRequest',body,config)
    .then(res =>{
        if(typeof callback==="function"){
            callback(res)
        }
    }).catch(err=>console.log(err))
}

export const payoutRequestsList = (data,callback) =>{
    
    const {token,userId} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/getPayoutRequests/'+userId,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


// admin payout

export const getPayoutRequestsAdmin = (data,callback) =>{
    
    const {token,userId} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/getPayoutRequestsAdmin/',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}





export const userRevanue = (data,callback) =>{
    
    const {token,userId} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/userRevanue/'+userId,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


export const userLastestActivity = (data,callback) =>{
    
    const {token,userId} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/userLastestActivity/'+userId,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


// payout  request single


export const payoutRequestById = (data,callback) =>{
    
    const {token,id} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/payoutRequestById/'+id,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}

export const sendNotificationCredit = (data,callback) =>{
    
    const {token,id} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/sendNotificationCredit/'+id,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}






export const AdminDashboardGraph = (data,callback) =>{
    
    const {token,filter} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/AdminDashboardGraph/'+ filter,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


export const sendaddcampignnotification = (data,callback) =>{
    
    const {token} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/sendaddcampignnotification/',config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}


export const sendNotifactionArchived = (data,callback) =>{
    
    const {token,title} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/sendNotifactionArchived/'+title,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}







export const campaignGraphById = (data,callback) =>{
    
    const {token,campid} = data;

    const config = {
        headers:{
            'Content-type':'application/json',
            'Authorization': `token ${token}`
        }
    }
   
   axios.get(server_url+'api/campaignGraphById/'+campid,config)
   .then(res =>{
       
    if(typeof callback==="function"){
        callback(res)
    }

       
   }).catch(err=>console.log(err));
}














