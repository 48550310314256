import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {WEB_URL} from '../../constants';
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({forceRefresh:true});

const isActive =(history,path) => {
   if(history.location.pathname === path){
      return 'active';
   }else{
      return false;
   }
}




const Sidebar = ({history}) =>(



    <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
         
            <div className="logo_top" >
                <Link to="/admin/dashboard" className="sidebar-brand d-flex align-items-center" >
               <img src={WEB_URL+'admin_assets/img/logo.png'} />
                </Link>
               
            </div>
            <li className={"nav-item " + isActive(history,'/admin/dashboard')}>
               <Link className="nav-link" to="/admin/dashboard"  >
            <img src={WEB_URL+"admin_assets/img/svg/Union.svg"} className="mr-2"/>
               <span>Übersicht</span>
               </Link>
            </li>
            <li className={"nav-item " + isActive(history,'/admin/Campaings')}>
               <Link className="nav-link" to="/admin/Campaings">
               <img src={WEB_URL+"admin_assets/img/svg/Path.svg"} className="mr-2"/>
               <span>Kampagnen</span>
               </Link>
            </li>
            {/* <li className={"nav-item " + isActive(history,'/admin/media')}>
               <Link className="nav-link" to="/admin/media">
               <img src={WEB_URL+"admin_assets/img/svg/Icon-metro-file-picture.svg"} className="mr-2"/>
               <span>Medien</span>
               </Link>
            </li> */}
            <li className={"nav-item " + isActive(history,'/admin/user-listing')}>
               <Link className="nav-link" to="/admin/user-listing">
               <img src={WEB_URL+"admin_assets/img/svg/icon_customers.svg"} className="mr-2"/>
               <span>User</span>
               </Link>
            </li>
            <li className={"nav-item " + isActive(history,'/admin/payments')}>
               <Link className="nav-link" to="/admin/payments">
               <img src={WEB_URL+"admin_assets/img/svg/icon_Invoices.svg"} className="mr-2"/>
               <span>Auszahlungen</span>
               </Link>
            </li>
            <li className={"nav-item " + isActive(history,'/admin/setting')}>
             <Link className="nav-link" to="/admin/setting">
             <i className="fas fa-sliders-h"></i>
                <span>Einstellungen</span>
             </Link>
          </li>
         </ul>
)


export default withRouter(Sidebar)