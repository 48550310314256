import React, { Component } from "react";

import { Form, Button, Alert } from "react-bootstrap";
import "../user_assets/css/auth-style.css";


import { register,isAuthenticated } from "../actions/auth";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({forceRefresh:true});

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class Signup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      username: null,
      email: null,
      password: null,
      confirm_password: null,
      agree: false,
      errors: {
        username: "",
        email: "",
        password: "",
        confirm_password: "",
        agree: false,
      },
      errors2: []
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "username":
        errors.username =
          value.length < 5 ? "Der Username muss mind. 5 Zeichen lang sein!" : "";
        break;
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Die Email ist ungültig!";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Das Passwort muss mind. 8 Zeichen lang sein!" : "";
        break;
      case "confirm_password":
        errors.confirm_password =
          value != this.state.password ? "Die Passwörter stimmen nicht überein!" : "";
        break;
      default:
        break;
    }
  };

  handelCheckbox = (e) => {
    const target = e.target;
    const value = target.value;

    const name = target.name;
    const type = target.type;

    this.setState({
      [name]: type === "checkbox" ? target.checked : value,
    });

    this.state.errors.agree = target.checked;
  
  };

  componentDidMount(){
    if(isAuthenticated()){
      this.props.history.push('/user/dashboard')
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var sed = true;

    if(this.state.errors.agree === false){
      this.setState({
        msg: "Bitte akzeptieren Sie den <a href='https://www.sharify.de/datenschutz' rel='noopener noreferrer'>Datenschutz</a> und die <a href='https://www.sharify.de/agb' rel='noopener noreferrer'>AGB</a>.",
        alert: "danger",
      });

    }

    

    if (
      this.state.username == null ||
      this.state.email == null ||
      this.state.password == null ||
      this.state.confirm_password == null
    ) {
      this.setState({
        msg: "Bitte alle Felder ausfüllen",
        alert: "danger",
      });
      sed = false;
    }

   
    
    if (
      validateForm(this.state.errors) &&
      this.state.errors.agree === true &&
      sed === true
    ) {
      

      const { username, email, password } = this.state;

      const newUser = {
        email,
        username,
        password,
      };

      this.setState({msg:"",isLoading: true });

      register(newUser, (res) => {

        if (res.data.success) {
          this.setState({
            msg: res.data.msg,
            alert: "success",
          });

          this.props.history.push('/waiting-for-activation')
          // console.log(res);
       

        } else {
          this.setState({
            isLoading:false,
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    } else {
    
    }
  };

 
   
  

  render() {
    const { errors } = this.state;

    return (
      <section className="sharify_user">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <div className="sharify_center_user">
                <div className="text_user text-center">
                  <div className="logo">
                    <img src="assets/images/logo_sharify.svg" />
                  </div>
                  <p>Willkommen, bitte erstelle deinen Account!</p>

                  {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                </div>
                <form
                  className="Sign-up"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          autoComplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.email.length > 0 && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          name="username"
                          autoComplete="on"
                          placeholder="Username"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.username.length > 0 && (
                          <span className="error">{errors.username}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Passwort"
                          name="password"
                          autoComplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.password.length > 0 && (
                          <span className="error">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Passwort wiederholen"
                          name="confirm_password"
                          autoComplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.confirm_password.length > 0 && (
                          <span className="error">
                            {errors.confirm_password}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group checkbox">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          value="1"
                          checked={this.state.agree}
                          name="agree"
                          autoComplete="on"
                          onChange={(e) => {
                            this.handelCheckbox(e);
                          }}
                        />
                        Ich stimme dem <a rel="noopener noreferrer" target="_blank" href="https://www.sharify.de/datenschutz">Datenschutz</a> und der <a rel="noopener noreferrer" target="_blank" href="https://www.sharify.de/agb">AGB</a> zu.
                      </div>
                      {errors.agree.length > 0 && (
                        <span className="error">{errors.agree}</span>
                      )}
                    </div>
                    <div className="col-md-12 text-center ">
                      <div className="form-group">
                      <button
                          type="submit"
                          className="btn_submit"
                          value="Submit"
                          disabled={this.state.isLoading}
                          
                        >
                          {this.state.isLoading ? "Laden..." : "Registrieren"}
                        </button>
                      </div>
                      <a className="mb-0" href="#" onClick={() => this.props.history.push('/login')} >Bereits einen Account? Einloggen!</a>

                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12 text-right">
            <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Signup;
