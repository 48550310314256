import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import {WEB_URL} from '../../constants';


import {logout,isAuthenticated} from '../../actions/auth';

import { createBrowserHistory } from 'history';
const history = createBrowserHistory({forceRefresh:true});


const isActive =(history,path) => {
  if(history.location.pathname === path){
     return 'active';
  }else{
     return false;
  }
}


const UserSidebar = ({history}) =>{

  const {user} = isAuthenticated();

return (

            <ul
              className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion tgo"
              id="accordionSidebar"
            > 

            <a
              className="sidebar-brand d-flex align-items-center justify-content-center"
              href=""
            >
              <img src={WEB_URL+"user_assets/img/logo_sharify.svg"} />
            </a>

            <li className="nav-item div_select_center mb-2">
              <a
                className="nav-link collapsed"
                href="#"
                data-toggle="collapse"
                data-target="#collapseTwo"
                aria-expanded="true"
                aria-controls="collapseTwo"
              >
                
                <span>{user.username}</span>
              </a>
              <div
                id="collapseTwo"
                className="collapse"
                aria-labelledby="headingTwo"
                data-parent="#accordionSidebar"
              >
                <div className="bg-white py-2 collapse-inner rounded">
                  <a className="collapse-item" href="#" onClick={() => logout()}>
                    AUSLOGGEN
                  </a>
                </div>
              </div>
            </li>

          

        
          <li className={"nav-item " + isActive(history,'/user/dashboard')}>
             <Link className="nav-link" to="/user/dashboard" >
             <i className="fas fa-home"></i>
                <span>Dashboard</span>
             </Link>
          </li>
          <li className={"nav-item " + isActive(history,'/user/Campaings')}>
             <Link className="nav-link" to="/user/Campaings">
             <i className="far fa-images"></i>
                <span>Kampagnen</span>
             </Link>
          </li>
          <li className={"nav-item " + isActive(history,'/user/payment')}>
             <Link className="nav-link" to="/user/payment">
             <i className="far fa-credit-card"></i>
                <span>Auszahlungen</span>             
             </Link>
          </li>
          {/* <li className={"nav-item " + isActive(history,'/user/bank')}>
             <Link className="nav-link" to="/user/bank">
             <i className="far fa-credit-card"></i>
                <span>Bank Detail</span>             
             </Link>
          </li> */}
          
          <li className={"nav-item " + isActive(history,'/user-setting')}>
             <Link className="nav-link" to="/user-setting">
             <i className="fas fa-sliders-h"></i>
                <span>Einstellungen</span>
             </Link>
          </li>
         
          
       </ul>
)

}

export default withRouter(UserSidebar)


