import React, { Component , Fragment } from "react";

import UserSidebar from './UserSidebar';
import ConfirmLink from 'react-confirm-dialog';



import { Form, Button, Alert } from "react-bootstrap";

import { updateprofile,isAuthenticated,authenticate,deleteAccount,logout } from "../../actions/auth";





// const token  = localStorage.getItem('token');





const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};





class UserSetting extends React.Component {
  // static contextType = AuthContext;

 

  
  
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      confirm_password: null,
      old_password:null,
      agree: false,
      token:"",
      errors: {
        username: "",
        password: "",
        confirm_password: "",
        old_password:"",
        agree: false,
      },

      errors2: []
    };

    
    

   

   
  }

componentDidMount()
{
  const {user} = isAuthenticated();

  this.setState({
    username: user.username,
    token: user.token,
  });
  if(window.innerWidth<480){
       document.getElementById("sidebarToggleTop").click();
     }
}








  handleChange = (event) => {
    const { name, value } = event.target;
    
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "username":
        errors.username =
          value.length < 5 ? "Der Username muss mindestens 5 Zeichen lang sein!" : "";
        break;
      case "password":
        errors.password =
          (value.length < 8  && value.length > 0) ? "Das Passwort muss mindestens 8 Zeichen lang sein!" : "";
        break;
      case "confirm_password":
        errors.confirm_password =
          value != this.state.password ? "Die Passwörter stimmen nicht überein." : "";
        break;
        case "old_password":
        errors.old_password =
        (value.length < 8  && value.length > 0)  ? "Das Passwort muss mindestens 8 Zeichen lang sein!" : "";
        break;
      default:
        break;
    }
  };

  


  handleSubmit = (event) => {
    event.preventDefault();
    var sed = true;
    let userName = (event.target.username) ? (event.target.username.value) : ('');
 
    this.state.username = userName;

    
    

    if (this.state.password != null)
     {

      if(this.state.confirm_password == null && this.state.old_password==null)
      {
        this.setState({
          msg: "Bitte fülle alle Felder aus",
          alert: "danger",
        });

        sed = false;
      }
    }

   
    
    if (
      validateForm(this.state.errors)  &&
      sed === true
    ) {
      

      const { username,password,old_password ,token} = this.state;


      const newUser = {
        old_password,
        username,
        password,
        token
      };

      //

      updateprofile(newUser, (res) => {
        if (res.data.success) {
          
          authenticate(res.data,()=>{
            this.setState({
              msg: res.data.msg,
              alert: "success",
            });
          });

          

          window.location.reload(false);

        } else {
          this.setState({
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    } else
     {
      this.setState({
        msg: "Bitte fülle alle Felder aus",
        alert: "danger",
      });
    }
  };

  deleteAccountHandler = () => {
    
    const {token} = this.state;
    const status = 3;

    const newUser = {
      token,status
    };


    deleteAccount(newUser, (res) => {
      if (res.data.success) {
        
        authenticate(res.data,()=>{
          this.setState({
            msg: 'Dein Account wurde erfolgreich gelöscht.',
            alert: "success",
          });
        });


        setTimeout(function(){
 
          logout()

     
        }, 5000);
        


      } else {
        this.setState({
          msg: res.data.msg,
          alert: "danger",
        });
      }
    });
    

  };

  

  render() {

    const { errors } = this.state;

    const {username} = this.state;

   


    
    return (
     
     
        <Fragment>
          <main className="dashboard_panel">
        <div id="wrapper"  >
         <UserSidebar/>

          <div id="content-wrapper" className="d-flex flex-column ">
            <div id="content" >
              <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top">
                <button
                  id="sidebarToggleTop"
                  className="btn btn-link d-md-none rounded-circle mr-3" >
                  <i className="fa fa-bars"></i>
                </button>
                <img src="user_assets/img/logo_sharify.svg" className="mobile_logo" />
              </nav>
              <div className="ctn_text_warp">
              <form className="pym_form" method="post" id="setting"  onSubmit={this.handleSubmit}  noValidate>
                <div className="container-fluid">
                  <div className="row">
                  
                    
                    <div className="col-md-12">
                      <div className="heading_text_ctn display_with">
                      {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                        <h2>Einstellungen</h2>
                        

                      
                  
                        
                      </div>
                    </div>
                    <div className="col-md-6 pr-5">
                      <div className="payment_sec_pym">
                        <h2>Passwort ändern</h2>
                       
                            
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Neues Passwort"
                                name="password"
                                autoComplete="on"
                                onChange={this.handleChange}
                                noValidate
                              />
                              {errors.password.length > 0 && (
                                <span className="error">{errors.password}</span>
                              )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Passwort wiederholen"
                                  name="confirm_password"
                                  autoComplete="on"
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.confirm_password.length > 0 && (
                                  <span className="error">
                                    {errors.confirm_password}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Altes Passwort"
                                  name="old_password"
                                  autoComplete="on"
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.old_password.length > 0 && (
                                  <span className="error">
                                    {errors.old_password}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="payment_sec_pym">
                        <h2>Username</h2>
                      
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  autoComplete="on"
                                  placeholder="Neuer Username"
                                  defaultValue={username}
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.username.length > 0 && (
                                  <span className="error">{errors.username}</span>
                                )}
                              </div>
                            </div>
                          </div>
                       
                      </div>
                      <div className="payment_sec_pym email_text">
                        <h2>Email</h2>
                       
                          <div className="row">
                            <div className="col-md-12">
                              <p>
                                Solltest du einmal deine Email ändern wollen, dann schreib unserem
                                Kundensupport doch bitte eine <a href="mailto:info@sharify.de" className="email-link">Nachricht</a>.
                              </p>
                            </div>
                            <div className="col-md-12 text-right mt-3 mb-2">
                              <div className="btn_request">

                                <button
                                  type="submit"
                                  className="btn_submit"
                                  value="Submit"
                                 >
                                  Speichern
                                </button>
                                {/* <a href="" type="submit">Save</a> */}
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    
                    <div className="col-md-12">
                      <div className="setting_scrolled email_text">
                        <h2>Konto</h2>
                        <p>
                        Du hast das Recht, dass wir dir alle deine bei uns gespeicherten personenbezogenen Daten zusenden. Schreibe
uns dazu bitte eine <a href="mailto:info@sharify.de" className="email-link">Email</a>.

                        </p>
                        <p>

                        Du hast jederzeit das Recht darauf, dass alle deine von uns gespeicherten personenbezogenen Daten gelöscht werden.
Dies passiert automatisch wenn du dein Konto löschst. Aber Vorsicht! Damit geht auch dein bisher verdientes Guthaben
verloren!
                        </p>
                      </div>
                      <div className="btn_request bg_color_grey">
                      <ConfirmLink action={() => this.deleteAccountHandler()} confirmMessage="Möchtest du dein Konto wirklich löschen? Dies kann nicht rückgängig gemacht werden." confirmText="Ja" cancelText="Nein" >
                      <button type="button" className="btn_submit"   >Konto löschen</button>
                      </ConfirmLink>
                      </div>
                    </div>
                    <div className="col-md-12 text-right mt-5">
                    <p className="mb-0 copy_right">©2021 S&amp;D Media Management UG (haftungsbeschränkt).
Alle Rechte vorbehalten.</p>
                    </div>
                  </div>
                  
              
                </div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
        </main>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        </Fragment>
 
    );
  }
}
export default UserSetting;
