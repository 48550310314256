import React, { Fragment, useEffect, useState } from 'react';
import {Link,useParams} from 'react-router-dom';
import {logout} from '../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../constants';
import {  isAuthenticated} from "../actions/auth";

import {generateCreditNote,payoutRequestsList,payoutRequestById} from "../actions/AdminApi";

import { Modal, Tab, Tabs } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useAlert } from 'react-alert';
import { get } from 'react-hook-form';
import  Moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import download from 'downloadjs';




//import download from 'downloadjs';




const CreditNote = () =>{
   var tax_calculate = 0;
 
   const params = useParams();
   const alert = useAlert()
   const [show, setShow] = useState(false);
   const [payout , setPayout] =  useState([]);
   const [isTax , setIsTax] =  useState();
   const [userbank , setUserbank] =  useState([]);
   const { user } = isAuthenticated();
   const token = user.token;
   const userId = user.id;
   var id = params.id;


   const init = () => {
   const Userdata = {
       token,id
     };
 
    
     payoutRequestById(Userdata,(res) => {
     

      if(res.data.status){
         setPayout(res.data.data);
         setUserbank(res.data.data.userbank)
         if(res.data.data.userbank.tax_information_person !== 1){
            setIsTax(1);
         }else{
            setIsTax(false);
         }
      }
   });
 };

 const tax = () =>{

   if(isTax){

 

      var  taxpercantage = 19/100;

        tax_calculate = taxpercantage*(payout.amount);
      var  ttfixed = (tax_calculate.toLocaleString('de-DE', {
         style: 'currency', 
         currency: 'EUR', 
         minimumFractionDigits: 2 
     }));

  return( <div class="border_ddr d-flex align-items-center justify-content-between">
   <div class="left_credit">
      <p class="mb-0">Umsatzsteuer 19%</p>
   </div>
   <div class="right_credit">
      <p class="mb-0">{ttfixed} </p>
   </div>
</div> )
   }
}

const amount = () =>{
   console.log(payout.amount);
   return payout.amount;
}

const totalAmount = () =>{
   var amt = tax_calculate+payout.amount;
   if(isTax){
      var  ttfixed = (amt.toLocaleString('de-DE', { 
         minimumFractionDigits: 2 
     }));
     return ttfixed;
   }else{
      return amt;
   }
   
}

const exportPdf = () => {

   const Userdata = {
      token,id
    };

   
     generateCreditNote(Userdata,(res) => {
    

     if(res.data.status){
        
      downloads();
       
     }
  });


 const downloads =  async () => {
   const res = await fetch(API_UPLOAD_PATH+"output.pdf");
   const blob = await res.blob();
   download(blob, 'output.pdf');
 }

//    html2canvas(document.querySelector("#page-top")).then(canvas => {
//       // document.body.appendChild(canvas);
//       var o = 'l';
//    if(window.innerWidth<1000){
//          o = 'p';
//          }
   
//       const imgData = canvas.toDataURL('image/png');
//       const pdf = new jsPDF(o,'mm',[canvas.width, canvas.height]);
//       pdf.addImage(imgData, 'png', 0, 10, canvas.width, canvas.height);
//       pdf.save("download.pdf"); 
//   });

}



 useEffect(() => {
    init();
},[]);


return (
   
    <Fragment>

       
<div data-html2canvas-ignore="true">
<button className="btn btn-success mt-5" onClick={()=>exportPdf()}>PDF herunterladen</button>
          </div>
         <main className="dashboard_panel" id="capture">
         
         <section class="Credit_note mt-10"  >
         <div class="container" >
            <div class="row">
               <div class="col-md-7">
                  <div class="credit_section ">
                     <h2>Gutschrift*</h2>
                     <p><span><strong># G-{payout._id}</strong></span></p>
                  </div>
                  <div class="credit_ntd mt-2">
                     <p class="mb-0">S&D Media Management UG (haftungsbeschränkt)</p>
                     <p class="mb-0">Salzungerpfad 25</p>
                     <p class="mb-0">12209 Berlin</p>
                     <p class="mb-0">Deutschland</p>

                  </div>
                  <div class="Receiver_ctn mt-4">
                     <h4>Empfänger</h4>
                     <p class="mb-0">{userbank.company}</p>
                     <p class="mb-0">{userbank.company_address}</p>
                     <p class="mb-0">{userbank.city}</p>
                     <p class="mb-0">{userbank.country}</p>
                     <p class="mb-0">{userbank.postal_code}</p>
                  </div>
               </div>
               <div class="col-md-5">
                  <img src={WEB_URL+"admin_assets/img/logo.svg"} />
               </div>
               <div class="col-md-12 text-right">
                  <h4>Datum</h4>
<p>{Moment(payout.createdOn).format('DD.MM.YYYY')}</p>
               </div>
               <div class="col-md-12 mt-4 mb-5">
                  <div class="border_ddr d-flex align-items-center justify-content-between">
                     <div class="left_credit">
                        <p class="mb-0"><span>Bezeichnung</span></p>
                     </div>
                     <div class="right_credit">
                        <p class="mb-0"><span>Betrag</span></p>
                     </div>
                  </div>
                  <div class="border_ddr d-flex align-items-center justify-content-between">
                     <div class="left_credit">
                        <p class="mb-0">Generierte Downloads durch Social Media Kanäle</p>
                        <p class="mb-0">Leistungszeitraum: {Moment(payout.start_date).format('DD.MM.YYYY')} - {Moment(payout.last_date).format('DD.MM.YYYY')}</p>
                     </div>
                     <div class="right_credit">
                        <p class="mb-0">{amount()} €</p>
                     </div>
                  </div>
                 
                  
                  {tax()}
                  <div class="border_ddr d-flex align-items-center justify-content-between">
                     <div class="left_credit">
                        <p class="mb-0">Gesamtbetrag</p>
                     </div>
                     <div class="right_credit">
                        <p class="mb-0">{ totalAmount()  } €</p>
                     </div>
                  </div>
               </div>
               <div class="col-md-12 mt-5 mb-5">
                  <p class="mb-0">Mit freundlichen Grüßen,</p>
                  <p class="mb-0">Dein S&D Media Management Team</p>
               </div>
            </div>
         </div>
      </section>
      <section class="footer_page mt-4 mb-5">
         <div class="container">
            <div class="row">
               <div class="col-md-12">
                  <p class="mb-0">S&D Media Management UG (haftbeschränkt)</p>
                  <p class="mb-0">Geschäftsführer: Max Sandig & Maximilian Von Duehren</p>
                  <p class="mb-0">Handelsregisternummer: 210145</p>
                  <p class="mb-0">Steuernummer: 29/530/30795</p>
                  <p class="mb-0">UST-ID: DE326971352</p>
               </div>
               <div class="col-md-12 text-right">
                  <p class="mb-0">*Gutschrift im Sinne des §14 Abs. 2 Satz 2 Umsatzsteuergesetz</p>
               </div>
            </div>
         </div>
      </section>
        
         </main>
       
        
        

       
        
        
        </Fragment>
        
 )
}

export default CreditNote;