import React, {Fragment, useEffect, useState } from 'react';
import UserSidebar from './UserSidebar';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {  isAuthenticated } from "../../actions/auth";

import { mediaListByCampign,mediaList,usercampiganList,getMediaUser,disLink} from "../../actions/AdminApi";

import { Modal, Tab, Tabs } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useAlert } from 'react-alert';
import { get } from 'react-hook-form';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import download from 'downloadjs';




const UserCampaign = () =>{
  var hideInput = {
    display: "none",
  };

 


  var poniter = {
   cursor: "pointer"
  }
    const alert = useAlert()
    const [show, setShow] = useState(false);
    const { user } = isAuthenticated();
    const token = user.token;
    const userid = user.id;



    const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [campaings , setCampaings] =  useState([]);
  const [stories , setStories] =  useState([]);
  const [feeds , setFeeds] =  useState([]);
  const [selectCampaing , setSelectCampaing] =  useState([]);
  const [selectCampaingcalculation , setSelectCampaingCalculation] =  useState([]);

  const [activekey, setActive] = useState("briefing");

  const init = () => {
    const Userdata = {
       token
     };
 
    
    usercampiganList(Userdata,(res) => {
       if(res.data.status){
          setCampaings(res.data.data)
       }
     });

     if(window.innerWidth<480){
         document.getElementById("sidebarToggleTop").click();
      }
 
 
 };

 const copyMsg = (cam) =>{
  
  var campid = cam._id;

  const userdata ={
    token,
    userid,
    campid
  }
  
disLink(userdata,(res) => {
  if(res.data.status){
    alert.success('Link kopiert');
  }
});


}

const changeActive = (key) => {
  setActive(key);
};


 const getCampign = (cid) =>
 {
   changeActive("briefing");
  var camp =[];

  setStories([]);
  setFeeds([]);
  camp=campaings[cid];
 setSelectCampaing(campaings[cid]);
 setSelectCampaingCalculation(campaings[cid].calculations);

 var type = 'stories';
 var id = camp._id;

 const storydata = {
  token,
  id,
  type
};




getMediaUser(storydata,(res) => {
  if(res.data.status){
     setStories(res.data.data);
  }
});


var type = 'feeds';
const feeddata = {
  token,
  id,
  type
};


getMediaUser(feeddata,(res) => {
  if(res.data.status){
     setFeeds(res.data.data);
  }
});

handleShow()



 }



 useEffect(() => {
    init();
},[]);



 return (
    <Fragment>
          <main className="dashboard_panel">
        <div id="wrapper"  >
         <UserSidebar/>

          <div id="content-wrapper" className="d-flex flex-column ">
          <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top">
                  <button id="sidebarToggleTop" className="btn btn-link sidebarToggleTop d-md-none rounded-circle mr-3 " >
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"user_assets/img/logo_sharify.svg"} className="mobile_logo" />
               </nav>
               <div className="ctn_text_warp">
                  <div className="container-fluid p-0">
                     <div className="row p-4">
                        <div className="col-md-12">
                           <div className="heading_text_ctn">
                              <h2>Kampagnen</h2>
                           </div>
                           <div className="Your_champ mt-3">
                      <div className="row">
                      {campaings.map((campain,i)=>(
                                    
                                    
                                 
                             
                        <div className="col-md-6 mt-3" key={i} style={poniter} onClick={() => getCampign(i)}>
                          <div>
                            <div className="box_camp shadow">
                              <div className="logo_box text-center" height="125px">
                                <img
                                  src={
                                    (campain.banner != null && campain.banner != 'undefined') ? API_UPLOAD_PATH + campain.banner:""
                                  }
                                />
                                
                                
                              </div>
                              <div className="box_champ_tt">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="champ_pyc">
                                      <img
                                        src={
                                          (campain.profile != null && campain.profile != 'undefined') ? API_UPLOAD_PATH + campain.profile:""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                  <div className="heading_tab">
                                       <h2>{campain.title}</h2>
                                      <div className="progress">
                                        <div className="bar" style={{width:campain.calculations.aviable_download+"%"}}></div>
                                      </div>
                                      <p className="mb-0">{campain.calculations.aviable_download}% verfügbar</p>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="right_price">
                                      <h4>Einnahme / Download</h4>
                                      <h2>{campain.user_per_download}€</h2>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-0">
                                    <p className="mb-0 clam_raid">
                                      {campain.briefing}
                                    </p>
                                  </div>
                                 
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                         ))}
                        
                      </div>
                    </div>
                        
                        </div>
                        <div className="col-md-12 text-right">
                           <p className="mb-0 copy_right">©2021 S&amp;D Media Management UG (haftungsbeschränkt).
Alle Rechte vorbehalten.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
        </main>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>


        <Modal id="exampleModal1"  show={show} onHide={handleClose}  dialogClassName="modalUser modal_section" centered>
        <Modal.Header closeButton>
        </Modal.Header>
            <div className="container-fluid p-0" >
            <Tab.Container
                id="left-tabs-example"
                activeKey={activekey}
                onSelect={(k) => setActive(k)}
              >
               <div className="row no-gutters align-items-center">
                  <div className="col-md-3">
                     <div className="tab">
                     <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link className="tablinks" eventKey="briefing">
                            Briefing
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="tablinks" eventKey="Media">
                            Medien
                          </Nav.Link>
                        </Nav.Item>
                       
                      </Nav>
                       
                     </div>
                  </div>
                  <div className="col-md-9">
                    <Tab.Content>
                    <Tab.Pane eventKey="briefing">

                     <div id="briefing" className="tabcontent shadow">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                           <div className="heading_tab">

                              <h2>{selectCampaing.title}</h2>
                           </div>
                           <div className="progess">
                           <div className="progress">
                           <div className="bar" style={{width:selectCampaingcalculation.aviable_download+"%"}}></div>

                            </div>
                            <p className="mb-0">{selectCampaingcalculation.aviable_download}% verfügbar</p>
                           </div>
                           <div className="btn_link">
                           <CopyToClipboard text={WEB_URL+"link/"+selectCampaing._id+"/"+user.id}>
                           <button onClick={ () => copyMsg(selectCampaing) }><span><i className="fa fa-link" aria-hidden="true"></i></span>Link kopieren</button>
                            </CopyToClipboard>
                           </div>
                        </div>
                        <div className="d-flex">
                           <div className="text_tabs" style={{minWidth:"75%"}}>
                              <p>{selectCampaing.briefing}
                              </p>
                              <p>{selectCampaing.description}
                              </p>
                           
                           </div>
                           <div className="right_price">
                              <h4>Einnahme / Download</h4>
                              <h2 className="text-center">{selectCampaing.user_per_download}€</h2>
                           </div>
                        </div>
                     </div>
                     </Tab.Pane>
                     <Tab.Pane eventKey="Media">

                     <div id="Media" className="tabcontent  shadow">
                        <div className="d-flex align-items-center justify-content-between mb-3">
                           <div className="heading_tab">
                              <h2>{selectCampaing.title}</h2>
                           </div>
                           <div className="progess">
                           <div className="progress">
                           <div className="bar" style={{width:selectCampaingcalculation.aviable_download+"%"}}></div>

                            </div>
                            <p className="mb-0 ">{selectCampaingcalculation.aviable_download}% verfügbar</p>
                           </div>
                           <div className="btn_link">
                           <CopyToClipboard text={WEB_URL+"link/"+selectCampaing._id+"/"+user.id}>
                           <button onClick={() => copyMsg(selectCampaing) }><span><i className="fa fa-link" aria-hidden="true"></i></span>Link kopieren</button>
                            </CopyToClipboard>
                           </div>
                        </div>
                        <div className="shadow_legends">
                           <div className="container">
                              <div className="row">
                                 <div className="col-md-12 mt-5">
                                    <Tabs defaultActiveKey="story" id="uncontrolled-tab-example" className="tb-col">
                                      <Tab eventKey="story" title="story">
                                      <div className="row mt-5">   


                                      {stories.map((story,i)=>(
                                    
                                    
                                    <div className="col-md-3 mm">
                                      <div className="box_legends mt-3">
                                      {(story.media=='video') ? ( <video   controls>
                                        <source src={API_UPLOAD_PATH + story.link} /> </video> ):(<img src={API_UPLOAD_PATH + story.link}/>)}
                                        <div className="d-flex align-items-center justify-content-center">
                                            <i className="fa fa-download" aria-hidden="true"></i>
                                            <p className="mb-0"><a href="#" onClick={async () => {
                                                            const res = await fetch(API_UPLOAD_PATH+story.link);
                                                            const blob = await res.blob();
                                                            download(blob, story.link);
                                                          }} >Download</a></p>
                                        </div>
                                      </div>
                                    </div>
                             
                                   
            
                                     ))}


                                 </div>
                                      </Tab>
                                      <Tab eventKey="feed" title="feed">
                                          <div className="row mt-5 ">     
                                          {feeds.map((feed,i)=>(                                    
                                              <div className="col-md-3 mm">
                                                <div className="box_legends feed mt-3">
                                                {(feed.media=='video') ? ( <video   controls>
  <source src={API_UPLOAD_PATH + feed.link} /> </video> ):(<img src={API_UPLOAD_PATH + feed.link}/>)}                                                  <div className="d-flex align-items-center justify-content-center">
                                                      <i className="fa fa-download" aria-hidden="true"></i>
                                                      <p className="mb-0"><a href="#" onClick={async () => {
                                                            const res = await fetch(API_UPLOAD_PATH+feed.link);
                                                            const blob = await res.blob();
                                                            download(blob, feed.link);
                                                          }} >Download</a></p>
                                                  </div>
                                                </div>
                                              </div>
                                                            
                                              ))}
                                          </div>
  

                                      </Tab>
                                      
                                    </Tabs>
                                 </div>
                               
                              </div>
                           </div>
                        </div>
                     </div>
                     </Tab.Pane>
                     </Tab.Content>
                  </div>
               </div>
               </Tab.Container>
            
      </div>
      </Modal>
        </Fragment>
 )
}

export default UserCampaign;