import React, { Fragment, useEffect, useState } from 'react';
import UserSidebar from './UserSidebar';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {  isAuthenticated } from "../../actions/auth";

import { userRevanue,userLastestActivity} from "../../actions/AdminApi";

import { Modal, Tab, Tabs } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useAlert } from 'react-alert';
import { get } from 'react-hook-form';
import download from 'downloadjs';
import  Moment from 'moment';
import 'moment/locale/de'  // germanize moment
Moment.locale('de');



const UserDashboard = () =>{
 
    const alert = useAlert()
    const [show, setShow] = useState(false);
    const [Revanue, setRevanue] = useState([]);
    const [activity, setActivity] = useState([]);
    const { user } = isAuthenticated();
    const token = user.token;



   
  const init = () => {
     var userId = user.id;
    const Userdata = {
       token,
       userId
     };
 
    
     userRevanue(Userdata,(res) => {
       if(res.data.status){
          setRevanue(res.data.data);
       }
     });


     userLastestActivity(Userdata,(res) => {
      if(res.data.status){
         setActivity(res.data.data);
      }
    });


     
 
 
 };


 useEffect(() => {
     init();
     if(window.innerWidth<480){
         document.getElementById("sidebarToggleTop").click();
      }
},[]);



 return (
    <Fragment>
          <main className="dashboard_panel">
        <div id="wrapper">
         <UserSidebar/>

          <div id="content-wrapper" className="d-flex flex-column ">
          <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top">
                  <button id="sidebarToggleTop" className="btn btn-link sidebarToggleTop d-md-none rounded-circle mr-3 " >
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"user_assets/img/logo_sharify.svg"} className="mobile_logo" />
               </nav>
               <div className="ctn_text_warp">
                  <div className="container-fluid">
                     <div className="row">
                        <div className="col-md-8">
                           <div className="heading_text_ctn">
                              <h2>Stats</h2>
                           </div>
                           <div className="Your_Revenue">
                              <div className="renenue_text">
                                 <h2>Einnahmen</h2>
                              </div>
                              <div className="row align-items-center">
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl1">
                                    <div className="total_month">
                                       <h3>Total</h3>
 <p className="mb-0 price_heading price_heading_color">{Revanue.total}€</p>
                                    </div>
                                 </div>
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl2">
                                    <div className="last_month ">
                                       <h3>Letzter Monat</h3>
                                       <p className="mb-0 price_heading">{Revanue.monthIncome}€</p>
                                    </div>
                                 </div>
                              </div>
                           </div>
                           <div className="ctn_Insights">
                              <div className="btm_heading">
                                 <h2>Allgemeines</h2>
                              </div>
                              <div className="row">
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl1 p-0">
                                    <div className="chart_ctn">
                                       <h4>Downloads</h4>
                                       <h1>{Revanue.linkDownloadCount}</h1>
                                    </div>
                                 </div>
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl1 p-0">
                                    <div className="chart_ctn">
                                       <h4>Conversion</h4>
                                       <h1>{ parseFloat(Revanue.conversion).toFixed( 2 ).toString().replace('.',',')}%</h1>
                                    </div>
                                 </div>
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl1 p-0">
                                    <div className="chart_ctn">
                                       <h4>Klicks</h4>
                                       <h1>{Revanue.linkClickCount}</h1>
                                    </div>
                                 </div>
                                 <div className="col-md-6 col-sm-6 col-xs-6 cl1  p-0">
                                    <div className="chart_ctn">
                                       <h4>Einnahmen/Download &Oslash;</h4>
                                       <h1>{parseFloat(Revanue.avarage).toFixed( 2 ).toString().replace('.',',')}€</h1>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="right_side_ctn">
                              <div className="heading_text_ctn">
                                 <h2>Aktivität</h2>
                              </div>
                              <div className="activity_list_section">
                                 
                                 <ul className="list_text_act">
                                 {activity.map((act,i)=>(
                                    <li key={i}>
                                       <div className="list_box">
                                 <h4><span>{Moment(act.createdOn).format('dddd Do MMMM, YYYY')}</span></h4>
                                 <h2>App Download ({act.AppName}) für {act.osName}</h2>
                                       </div>
                                    </li>
                                ))}
                                   
                              
                                 </ul>
                              </div>
                           </div>
                        </div>
                        <div className="col-md-12 text-right">
                           <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).
Alle Rechte vorbehalten.</p>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
        </main>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>


        
        </Fragment>
 )
}

export default UserDashboard;