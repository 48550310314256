import React, { Component } from "react";

import { Form, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { verifyaccount } from "../actions/auth";
import { AuthContext } from '../contexts/AuthContext';
import "../user_assets/css/auth-style.css";


import logo from '../user_assets/img/logo_sharify.svg';

import queryString from 'query-string';





class Verification extends Component {

  static contextType = AuthContext;
  constructor(props) {
    
    super(props);
    this.state = {
      token:this.props.match.params.token
      
    };

    

  }



  componentDidMount(){

    if(this.context.isAuthenticated){
        this.props.history.push('/')
      }

    const  { token } = this.state;

    const newUser = {
     token
    
   };

    verifyaccount(newUser, (res) => {
        if (res.data.success) {
          this.setState({
            success:true ,
            msg: res.data.msg,
            alert: "success",
          });
          
        

        } else {
          this.setState({
            success:false,
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    
  }



  render() {
    

    return (
        <section className="sharify_user">
        <div className="container">
            <div className="row">
                <div className="col-md-7 mx-auto">
                    <div className="sharify_center_user">
                        <div className="text_user text-center">
                            <div className="logo">
                                <img src={logo}/>
                            </div>
                            
                            <p className="text_password_top">{this.state.success ? (
                    'Aktivierung erfolgreich!'
                  ) : ('Dein Link ist abgelaufen!')}</p>
                            
                        </div>
                        <form className="Successfully-activated">
                            <div className="row">
                                
                                <div className="col-md-12 text-center ">
                                    <div className="form-group">
                                        <button  onClick={() => this.props.history.push('/login')}  className="btn_submit" value="Submit">Zur Anmeldung</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="col-md-12 text-right">
                <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
export default Verification ;
