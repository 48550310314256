import React, { Fragment, useState } from 'react';
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {WEB_URL} from '../../constants';
import {AdminDashboardGraph} from "../../actions/AdminApi";
import { isAuthenticated } from "../../actions/auth";


import ApexCharts from 'apexcharts';
import Chart from "react-apexcharts";






class Dashboard extends React.Component{

   constructor(props) {
      super(props);

      this.state = {
      
        series:[],
        options: {
          chart: {
            height: 350,
            type: 'area'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'month',
            categories: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul","Aug","Sep","Okt","Nov","Dez"]
          },
          tooltip: {
            x: {
              format: 'MM'
            },
          },
        },
        caldata:[],
        campList2:[],
        items: [],
      visible: 4,
      error: false,
      filter:'Revenue'
      
      
      };
      this.loadMore = this.loadMore.bind(this);
    }

    loadMore() {
      this.setState((prev) => {
        return {visible: prev.visible + 4};
      });
    }

     

    handleChange = (event) => {
      this.setState({ filter: event.target.value });
      this.init(event.target.value);
    };

      init(filter) {

      const {user} = isAuthenticated();
      var token = user.token;
      var filter = filter;
      const Userdata ={
         token,
         filter
      }

      AdminDashboardGraph(Userdata,(res) => {
         if(res.data.status==true){
   
            this.setState({
               series:res.data.result,
               caldata:res.data.calData,
               campList2:res.data.campList,
               items:res.data.campList
            });
            
         }
   
         
      });
    }

componentDidMount()
{
 
   this.init('Revenue');

   // setTimeout({
   //    document.getElementById("myCheck").click()
   // },1000);
if(window.innerWidth<480){
      document.getElementById("sidebarToggleTop").click();

   }

  
 
}

    render() {

 return (
    <Fragment>
   <main className="admin_dashboard_panel">

       <div id="wrapper"  >
         <Sidebar/>
         <div id="content-wrapper" class="d-flex flex-column" style={{padding: "0px"}}>
         
            <div id="content">
            
               <nav class="navbar navbar-expand navbar-light topbar static-top ">
                  <button id="sidebarToggleTop" class="btn btn-link d-md-none rounded-circle mr-3">
                  <i class="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"admin_assets/img/logo_sharify.svg"} class="mobile-logo" />
               </nav>
               <div class="ctn_text_warp">
                  <div class="box_top_bar d-flex align-items-center justify-content-end">
                     <div class="ctn_logout float-right">
                        <Link to="" onClick={() => logout()}>Ausloggen</Link>
                     </div> 
                  </div>
                  <div class="container-fluid p-0 ">
                     <div class="row p-3">
                        <div class="col-md-12">
                           <div class="dashboard_heading mb-5">
                              <h2 class="mb-0">Overview</h2>
                           </div>
                           <div class="dashboard_box">
                              <div class="row">
                                 <div class="col-md-8">
                                    <div class="box_dash">
                                       <div class="row">
                                          <div class="col-md-6">
                                             <div class="dropdown">
                                                <select name="filter" class="form-control col-xs-3" value={this.state.filter}  onChange={this.handleChange}>
                                                
                                                   <option class="Revenue">Turnover</option>
                                                   <option class="Profit">Profit</option>
                                                   <option class="Clicks">Clicks</option>
                                                   <option class="Downloads">Downloads</option>
                                                </select>
                                                {/* <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Sale
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                   <a class="dropdown-item" href="#">1</a>
                                                   <a class="dropdown-item" href="#">2</a>
                                                   <a class="dropdown-item" href="#">3</a>
                                                </div> */}
                                             </div>
                                          </div>
                                          <div class="col-md-6 text-right">
                                             {/* <div class="dropdown">
                                                <button class="btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                Last 6 Month
                                                </button>
                                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                   <a class="dropdown-item" href="#">1</a>
                                                   <a class="dropdown-item" href="#">2</a>
                                                   <a class="dropdown-item" href="#">3</a>
                                                </div>
                                             </div> */}
                                          </div>
                                          <div class="col-md-12 mt-3">
                                             <div class="graph_info dashboard_admin_graph">
                                             <div id="chart">
                                             <Chart options={this.state.options} series={this.state.series} type="area" height={350} />
</div>
                                              {/* <img src={WEB_URL+"admin_assets/img/Component.jpg"}></img> */}
                                              
                                                <div class="row mt-4">
                                                   <div class="col-md-3">
                                                      <div class="graph_box box_dash_admin">
                                                         <h3 class="mb-0 color_box0">{this.state.caldata.totalReavnue} EURO</h3>
                                                         <h2>Umsatz</h2>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-3">
                                                      <div class="graph_box box_dash_admin">
                                                         <h3 class="mb-0 color_box10">{this.state.caldata.profit} EURO</h3>
                                                         <h2>Gewinn</h2>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-3">
                                                      <div class="graph_box box_dash_admin">
                                                         <h3 class="mb-0 color_box20">{this.state.caldata.linkClickCount}</h3>
                                                         <h2>Klicks</h2>
                                                      </div>
                                                   </div>
                                                   <div class="col-md-3">
                                                      <div class="graph_box box_dash_admin">
                                                         <h3 class="mb-0 color_box30 ">{this.state.caldata.linkDownloadCount}</h3>
                                                         <h2>Downloads</h2>
                                                      </div>
                                                   </div>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div class="col-md-4">
                                    <div class="box_right">
                                       <div class="download_section">
                                          <ul>
                                             <li>
                                                <div class="right_list">
                                                   <h2>Download</h2>
                                                </div>
                                             </li>
                                             
                                             {this.state.items.slice(0, this.state.visible).map((item, index) => {
                                                return (
                                                <li>
                                                   <div class="right_list d-flex align-items-center justify-content-between">
                                                      <h2>{item.title}</h2>
                                                     <a href={"/admin/campaingGraph/"+item._id}> <i class="fa fa-download" aria-hidden="true"></i></a>
                                                   </div>
                                                </li>
                                                );
                                                })}
                                                {this.state.visible < this.state.items.length &&
                                                <li><button onClick={this.loadMore} type="button" className="btn btn-success">mehr laden</button></li>
                                             }
                                          </ul>
                                          
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      </main>
      
  
      <a class="scroll-to-top rounded" href="#page-top">
      <i class="fas fa-angle-up"></i>
      </a>
      </Fragment>
 )
}
}

export default Dashboard;