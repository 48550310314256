import React, { Fragment, useState } from 'react';
import { useParams } from "react-router-dom";
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {WEB_URL} from '../../constants';
import {campaignGraphById} from "../../actions/AdminApi";
import { isAuthenticated } from "../../actions/auth";
import  Moment from 'moment';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';



import ApexCharts from 'apexcharts';
import Chart from "react-apexcharts";






class CampainGraph extends React.Component{

   constructor(props) {
      super(props);

      this.state = {
        campid:this.props.match.params.id,
        series:[],
        options: {
          chart: {
            height: 350,
            type: 'area'
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth'
          },
          xaxis: {
            type: 'month',
            categories: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul","Aug","Sep","Okt","Nov","Dez"]
          },
          tooltip: {
            x: {
              format: 'MM'
            },
          },
        },
        caldata:[],
        campList2:{}
      
      
      };
    }

componentDidMount()
{
   const {user} = isAuthenticated();
   var token = user.token;
   var campid = this.state.campid;
   const Userdata ={
      token,
      campid
   }
   campaignGraphById(Userdata,(res) => {
      if(res.data.status==true){

        this.setState({
            series:res.data.result,
            caldata:res.data.calData,
            campList2:res.data.campList
         });

         
      }

      
   });

 
 
}

exportPdf() {

   html2canvas(document.querySelector("#page-top")).then(canvas => {
      // document.body.appendChild(canvas);
      var o = 'l';


   
      const imgData = canvas.toDataURL('image/png');
      if(window.innerWidth<1000){
         o = 'p';
         }
      const pdf = new jsPDF(o,'mm',[canvas.width, canvas.height]);
      pdf.addImage(imgData, 'png', 0, 0, canvas.width, canvas.height);
      pdf.save("download.pdf"); 
  });
}

    render() {

 return (
    <Fragment>
       <button data-html2canvas-ignore="true" className="btn btn-success mt-5" onClick={this.exportPdf}>PDF speichern</button>
       <main id="capture">
        <section className="pb-5 pt-5">
         <div className="container">
            <div className="row">
               <div className="col-md-6">
                  <div className="campaign_heading">
                     <h2>Kampagnenauswertung</h2>
                     <h4>{this.state.campList2.title}</h4>
                  </div>
               </div>
               <div className="col-md-6 text-right">
                  <div className="ctn_campaign_right">
                     <img src={WEB_URL+"admin_assets/img/logo.png"}/>
                  </div>
               </div>
               <div className="col-md-12 mt-5 text-right">
                  <div className="date_right">
                     <h4><strong>Datum</strong></h4>
                     <p className="mb-0">{Moment().format('DD.MM.YYYY')}</p>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="info_graphic">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <div className="graph_info shadow">
                     {/* <img src={WEB_URL+"admin_assets/img/svg/graph_svg.svg"}/> */}
                     <div id="chart">
                         <Chart options={this.state.options} series={this.state.series} type="area" height={350} />
</div>
                    
                     <div className="row mt-4">
                        <div className="col-md-4">
                           <div className="graph_box">
                              <h3 className="mb-0 color_box">{Moment(this.state.campList2.campaigns_from_date).format('DD.MM.YYYY')} - {Moment(this.state.campList2.campaigns_till_date).format('DD.MM.YYYY')}</h3>
                              <h2>Laufzeit</h2>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="graph_box">
                              <h3 className="mb-0 color_box1">{this.state.caldata.linkClickCount}</h3>
                              <h2>Klicks</h2>
                           </div>
                        </div>
                        <div className="col-md-4">
                           <div className="graph_box">
                              <h3 className="mb-0 color_box2">{this.state.caldata.linkDownloadCount}</h3>
                              <h2>Downloads</h2>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <section className="Sincerely_sec">
         <div className="container">
            <div className="row">
               <div className="col-md-12">
                  <h2>Mit freundlichen Grüßen,</h2>
                  <h2>Dein S&D Media Management Team</h2>
               </div>
            </div>
         </div>
      </section>
      <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
      </a>
      </main>
    </Fragment>
 )
}
}

export default CampainGraph;