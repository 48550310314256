import React, { Component,Fragment, useEffect, useState } from 'react';
import {Link, useParams} from 'react-router-dom';
import { Modal, Tab, Tabs } from "react-bootstrap";
import {WEB_URL,API_UPLOAD_PATH} from '../constants';
import {  isAuthenticated } from "../actions/auth";
import { linkClicking } from "../actions/AdminApi";
  import { deviceDetect,deviceType,osName,osVersion,mobileModel,mobileVendor } from 'react-device-detect';

const LinkTracking = () =>{
    
  
  const params = useParams();


  // console.log(deviceType);
  // console.log(osName);


  const init = () => {
   

   

   
 var campaign = params.campid

var user =  params.userid;


 

const payload ={
  campaign,
  user,
  osName,
  mobileModel,
  mobileVendor
}



linkClicking(payload, (res) => {

  if (res.data.status)
   {
   if(osName==='ios')
   {
    window.location = res.data.data.linkIos;

  } else
   {
     console.log(res.data.data.linkAndroid);
    window.location = res.data.data.linkAndroid;
  }
}
});


    

 
 
 
 };

   useEffect(() => {
      init();
  },[]);
  
return (<div>

</div>)
  
   
  }
  
  export default LinkTracking;
