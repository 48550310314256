import React, { Fragment, useEffect, useState,useRef } from 'react';
import Sidebar from './Sidebar';
import {Link} from 'react-router-dom';
import {logout,isAuthenticated} from '../../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {mediaList,updateStatusMedia,deleteMedia} from '../../actions/AdminApi';
import DataTable from 'react-data-table-component';
import { useAlert } from 'react-alert';
import  Moment from 'moment';






const MediaListing = () =>{
   const alert = useAlert()

  

    const {user} =  isAuthenticated();

      const [media , setMedia] =  useState([])
      const [error, setError] = useState(false)
      const token = user.token;

      const init = () => {
         const Userdata = {
            token
          };

         
         mediaList(Userdata,(res) => {
            if(res.data.status){
               setMedia(res.data.data)
            }else{
               setError(res.data.data)
            }
          });


         


      };

      const data = media;
      const columns = [
        {
          name: 'Media',
          selector: 'link',
          cell: row => (row.media=='video') ? ( <video   controls width="180px" height="180px">
          <source src={API_UPLOAD_PATH + row.link} /> </video> ):(<img src={API_UPLOAD_PATH + row.link}  width="150px" height="150px" />),
         },
       
        {
          name: 'Type',
          selector: 'mediatype',
          sortable: true,
        },
        {
         name: 'Status',
         selector: 'status',
         sortable: true,
         cell: row =><a href="#" onClick={() => changeStatus(row)} >{(row.status===1) ? 'Active':'Deactive' }</a>,

       },
       {
         name: 'CreatedOn',
         selector: 'createdOn',
         sortable: true,
         cell: row =>Moment(row.createdOn).format('YYYY-MM-DD'),

         
       },
       {
         name: 'Action',
         selector: 'action',
         cell: row => <a href="#" onClick={() => deletemedia(row)} ><img src={WEB_URL+"admin_assets/img/svg/Icon-delete.svg"} /></a>,
       },
      ];

      const changeStatus = (obj) =>{

         var mediaid = obj._id;
        var status = (obj.status==1) ? 2:1;

         const newUser = {
            mediaid,
            status,
            token
          };
    
          //
    
          updateStatusMedia(newUser, (res) => {
            if (res.data.success) {
               alert.success('Erfolgreich aktualisiert');

               init()
            }else{
               alert.error('Da ist was schief gelaufen');

            } 
          });


      }

      const deletemedia = (obj) =>{

         var mediaid = obj._id;

         const newUser = {
            mediaid,
            token 
          };
    
          //
    
          deleteMedia(newUser, (res) => {
            if (res.data.success) {
               alert.success('Erfolgreich gelöscht');

               init()
            } else{
               alert.error('Da ist was schief gelaufen');

            }
          });


      }

      const mounted = useRef();
     
      useEffect(() => {
         if(window.innerWidth<480){
               document.getElementById("sidebarToggleTop").click();
         
            }

         if (!mounted.current) {
            init()
            mounted.current = true;
          } else {
            init()
          }
      },[])

     


 return (
    
    <Fragment>
             <main className="admin_dashboard_panel">

      <div id="wrapper"  >
         <Sidebar/>
         <div id="content-wrapper" className="d-flex flex-column" style={{padding: "0px"}}>
            <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar static-top ">
                  <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3">
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"admin_assets/img/logo_sharify.svg"} className="mobile-logo" />
               </nav>
               <div className="ctn_text_warp">
                  <div className="box_top_bar d-flex align-items-center justify-content-between">
                     <div className="search d-flex align-items-center">
                        <p className="mb-0 ml-2">Medienübersicht</p>
                     </div>
                     <div className="ctn_logout float-right">
                     <Link to="" onClick={() => logout()}>Abmelden</Link>
                     </div>
                  </div>
                  <div className="container-fluid p-0">
                     <div className="row p-4">
                        <div className="col-md-12">
                           <div className="heading_text_ctn">
                              <h2 className="mr-5 pr-5">Medien</h2>
                           </div>
                        </div>
                        <div className="col-md-12 mt-3 admin_user">
                        <DataTable
                           columns={columns}
                           data={data}
                           pagination={true}
                           className="rwd-table rwd-table2 shadow"
                           />
                         
                        </div>
                        {/* <div className="col-md-12 text-right mt-5">
                           <div className="content_detail__pagination cdp" actpage="1">
                              <a href="#!-1" className="cdp_i"><img src={WEB_URL+"admin_assets/img/svg/right-icon.svg"}/></a>
                              <a href="#!1" className="cdp_i active">1</a>
                              <a href="#!2" className="cdp_i">2</a>
                              <a href="#!3" className="cdp_i">3</a>
                              <a href="#!4" className="cdp_i">4</a>
                              <a href="#!5" className="cdp_i">5</a>
                              <a href="#!+1" className="cdp_i"><img src={WEB_URL+"admin_assets/img/svg/left-icon.svg"}/></a>
                           </div>
                        </div> */}
                     </div>
                  </div>
               </div>
            </div>
         </div>
         </div>
      </main>
  
      <a className="scroll-to-top rounded" href="#page-top">
      <i className="fas fa-angle-up"></i>
      </a>
      </Fragment>
 )
}

export default MediaListing;