import React, { Component } from "react";

import { Form, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";

import { resetpassword } from "../actions/auth";
import { AuthContext } from '../contexts/AuthContext';
import "../user_assets/css/auth-style.css";


import logo from '../user_assets/img/logo_sharify.svg';

import queryString from 'query-string';





class WaitingActivation extends Component {

  static contextType = AuthContext;
  constructor(props) {
    
    super(props);
    this.state = {
      
      
    };

  }

  componentDidMount(){
    if(this.context.isAuthenticated){
      this.props.history.push('/')
    }
  }



  render() {
    

    return (
        <section className="sharify_user">
        <div className="container">
            <div className="row">
                <div className="col-md-7 mx-auto">
                    <div className="sharify_center_user login-page">
                        <div className="text_user text-center">
                            <div className="logo">
                                <img src="assets/images/logo_sharify.svg"/>
                            </div>
                           
                        </div>
                        <div className="Waiting-for-activation">
                            <div className="row">
                                <div className="col-md-12 text-center waiting-activation">
                                    <p>Anmeldung erfolgreich!</p>
                                    <p>Wir haben dir einen Link zur Aktivierung deines Accounts per Email zugesendet!</p>
                                    
                                </div>
                                
                                
                                <div className="col-md-12 text-center ">
                                    <div className="form-group">
                                        <button className="btn_submit" onClick={() => this.props.history.push('/login')} >Zurück zur Startseite</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 text-right">
                <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
                </div>
            </div>
        </div>
    </section>
    );
  }
}
export default WaitingActivation ;
