import React, { Component } from 'react';
import { BrowserRouter ,Switch, Route } from 'react-router-dom'


import { login } from './actions/auth'
import Register from './components/Signup';
import Login from './components/Login';
import UserSetting from './components/user/UserSetting';
import ForgotPassword from './components/ForgetPassword';

import ChangePassword from './components/ChangePassword';
import WaitingActivation from './components/WaitingActivation';
import Verification from './components/Verification';
import SuccessfullyChanged from './components/SuccessfullyChanged';
import PrivateRoute from './actions/PrivateRoute';
import AdminRoute from './actions/AdminRoute';
import Dashboard from './components/admin/Dashboard';
import UserListing from './components/admin/UserListing';
import MediaListing from './components/admin/MediaListing';

import CampaingListing from './components/admin/CampaingListing';
import PaymentRequest from './components/admin/PaymentRequest';

import UserCampaign from './components/user/UserCampaign';
import LinkTracking from './components/LinkTracking';

import AdminSetting from './components/admin/AdminSetting';
import UserDashboard from './components/user/UserDashboard';
import UserPayment from './components/user/UserPayment';
// import UserBank from './components/user/UserBank';
import CreditNote from './components/CreditNote';
import CampainGraph from './components/admin/CampainGraph';

function App() {
 
  return (
    <BrowserRouter basename="/">
      <Switch>
         
          <Route exact path="/register" component={Register}/>
          <Route exact path="/"  component={Login} />
          
          <Route exact path="/login"  component={Login} />
          <Route exact path="/forgot-password"  component={ForgotPassword} />
          <Route exact path="/reset-password/:token"  component={ChangePassword} />
          <Route exact path="/link/:campid/:userid"  component={LinkTracking} />
         

          <Route exact path="/waiting-for-activation"  component={WaitingActivation} />
          <Route exact path="/account-verification/:token"  component={Verification} />
          <Route exact path="/password-changed"  component={SuccessfullyChanged} />



          <PrivateRoute exact path="/user-setting"  component={UserSetting} />
          <PrivateRoute exact path="/user/Campaings"  component={UserCampaign} />

          <PrivateRoute exact path="/user/dashboard"  component={UserDashboard} />
          <PrivateRoute exact path="/user/payment"  component={UserPayment} />
          <PrivateRoute exact path="/creditNote/:id"  component={CreditNote} />
         




          


          {/* admin route */}
           <AdminRoute exact path="/admin/dashboard"  component={Dashboard} />
          <AdminRoute exact path="/admin/user-listing"  component={UserListing} />
          <AdminRoute exact path="/admin/Campaings"  component={CampaingListing} />
          <AdminRoute exact path="/admin/media"  component={MediaListing} /> 
          <AdminRoute exact path="/admin/setting"  component={AdminSetting} /> 
          <AdminRoute exact path="/admin/payments"  component={PaymentRequest} /> 
          <AdminRoute exact path="/admin/campaingGraph/:id"  component={CampainGraph} /> 

          

          


          
          </Switch>
      </BrowserRouter>
  );
}



 
export default App;