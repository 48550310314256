import React, { Component } from "react";

import { Form, Button, Alert } from "react-bootstrap";

import { forgotpassword } from "../actions/auth";
import { AuthContext } from '../contexts/AuthContext';
import "../user_assets/css/auth-style.css";
import { createBrowserHistory } from 'history';
const history = createBrowserHistory({forceRefresh:true});


const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};

class ForgotPassword extends React.Component {

  static contextType = AuthContext;

  




  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      errors: {
        email: "",
      }
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Die Email ist ungültig!";
        break;
      default:
        break;
    }
  };

 


  handleSubmit = (event) => {
    event.preventDefault();
    var sed = true;

    

    if (
      this.state.email == null
    ) {
      this.setState({
        msg: "Bitte alle Felder ausfüllen",
        alert: "danger",
      });
      sed = false;
    }

    
    if (
      validateForm(this.state.errors) &&  sed === true
      )
      {
      

      const { email, password } = this.state;

      const newUser = {
        email
      };

      //
      this.setState({msg:"",isLoading: true });
      forgotpassword(newUser, (res) => {
        if (res.data.success) {
          this.setState({
            msg: res.data.msg,
            alert: "success",
            isLoading:false
          });
         
         

        } else {
          this.setState({
            msg: res.data.msg,
            alert: "danger",
            isLoading:false
          });
        }
      });
    } else {
    
    }
  };


  render() {
    const { errors } = this.state;
    return (

      <section className="sharify_user">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <div className="sharify_center_user login-page">
                <div className="text_user text-center">
                  <div className="logo">
                    <img src="assets/images/logo_sharify.svg" />
                  </div>
                  <p>Gib deine Email ein, und wir senden dir einen reset Link!</p>
                  {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                </div>
                <form
                  className="login"
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          autoComplete="on"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.email.length > 0 && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                   

                    <div className="col-md-12 text-center ">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn_submit"
                          value="Submit"
                          disabled={this.state.isLoading}

                        >
                            {this.state.isLoading ? "Laden..." : "Link erhalten!"}
                          
                        </button>
                      </div>
                      <div class="col-md-12 text-center">
                                        <p class="mb-0 Forgot-Password"><a href="#" onClick={() => this.props.history.push('/login')}>Zurück zur Anmeldung</a></p>
                                    </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12 text-right">
            <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ForgotPassword;
