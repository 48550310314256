import React, { Fragment, useEffect, useState } from 'react';
import UserSidebar from './UserSidebar';
import {Link} from 'react-router-dom';
import {logout} from '../../actions/auth';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {  isAuthenticated } from "../../actions/auth";

import {sendNotificationCredit,saveBankDetails,getBankDetails,sendPayoutRequest,userRevanue,payoutRequestsList} from "../../actions/AdminApi";

import { Modal, Tab, Tabs } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import { useAlert } from 'react-alert';
import { get, useForm } from 'react-hook-form';
import ConfirmLink from 'react-confirm-dialog';
import  Moment from 'moment';

const ibantools = require('ibantools');



//import download from 'downloadjs';


const UserPayment = () =>{
   const isIban = (value) => {
     return  ibantools.isValidIBAN(value);
     
   }

   const isBic = (value) => {
      return  ibantools.isValidBIC(value);
      
    }
   const alert = useAlert()
   const [show, setShow] = useState(false);
   const [payoutRequests , setpayoutRequests] =  useState([]);
   const [Revanue, setRevanue] = useState([]);
   const [tax_top_text, setTaxtopText] = useState('Private person');
   const [tax_bottom_text, setTaxbottomText] = useState('Vorsicht: Mit der Beantragung einer Auszahlung wirst du unternehmerisch tätig und solltest dies beim Finanzamt anmelden!');


   const { user } = isAuthenticated();
   const token = user.token;
   const userId = user.id;
   const init = () =>
   {
      const Userdata = {
         token,userId
      };
   
     
      
      payoutRequestsList(Userdata,(res) => {
         

         if(res.data.status){
            setpayoutRequests(res.data.data);
         }
      });


      
   
      
       userRevanue(Userdata,(res) => {
         if(res.data.status){
            setRevanue(res.data.data);
         }
       });




 };

 

 const onSubmit = data => {
   const formdata = {
       company,
   }
};






const handleChange = (name) => (event) => {
   const value = event.target.value;
   setValues({ ...values, [name]: value });

   if(name==='tax_information_person'){
      if(event.target.value==2){
         setTaxtopText('Small business');
         setTaxbottomText('Bitte denke daran uns umgehend zu informieren, wenn sich etwas an Deinem Status als Kleinunternehmer ändern sollte!');
      }else if(event.target.value==3){
         setTaxtopText('Business');
         setTaxbottomText('');


      }else{
         setTaxtopText('Private person')
         setTaxbottomText('Vorsicht: Mit der Beantragung einer Auszahlung wirst du unternehmerisch tätig und solltest dies beim Finanzamt anmelden!');

      }
   }


};




const onSetupOne = data =>{

   const bankdetail = {

   };
  
   data.token = token;
   data.userid = user.id;
   saveBankDetails(data, (res) => {
       if (res.data.status==true) {
           alert.success('Bankverbindung erfolgreich gespeichert');
       
       } 
   });
}
const handlePayout = data => {
   const postData = {
       token:user.token,
       userid:user.id
   };
   
   sendPayoutRequest(postData, (res) => {
       if (res.data.status==true) {
         init();

         const data2 ={
            token:user.token,
            id:res.data.data._id
         }

         sendNotificationCredit(data2, (res) => {
            if (res.data.status==true) {
            
            } 
        });
         
           alert.success('Auszahlung erfolgreich beantragt');

       
       } else{
           alert.error(res.data.msg);
       }
   });
};



const [values, setValues] = useState({        
   company: "",
   company_address:"",
   postal_code:"",
   city:"",
   country:"",
   account_holder:"",
   iban:"",
   bic:"",
   additional_optional:"",
   tax_number:"",
   tax_information_person:"",
});







 useEffect((data) => {
    init();
    if(window.innerWidth<480){
         document.getElementById("sidebarToggleTop").click();
         }
    let userId = user.id;
    const userdata = {
        token,
        userId
   };
    
    getBankDetails(userdata, (res) => {
        if (res.data.success==true) {

         const value = res.data.data;
            setValues(
                { "account_holder":res.data.data.account_holder,
                "bic":res.data.data.bic,
                "city":res.data.data.city,
                "company":res.data.data.company,
                "country":res.data.data.country,
                "iban":res.data.data.iban,
                "postal_code":res.data.data.postal_code,
                "company_address":res.data.data.company_address,
                "additional_optional":res.data.data.additional_optional,
                "tax_number": res.data.data.tax_number,
                "tax_information_person": res.data.data.tax_information_person
                }
            );
        
        } 
    });



},[]);

const { register, handleSubmit, watch, errors } = useForm();

const {
   company,
   company_address,
   postal_code,
   city,
   country,
   account_holder,
   iban,
   bic,
   additional_optional,
   tax_number,
   tax_information_person
} = values;


return (
    <Fragment>
          <main className="dashboard_panel">
        <div id="wrapper">
         <UserSidebar/>

          <div id="content-wrapper" className="d-flex flex-column ">
          <div id="content">
               <nav className="navbar navbar-expand navbar-light topbar mb-4 static-top">
                  <button id="sidebarToggleTop" className="btn btn-link sidebarToggleTop d-md-none rounded-circle mr-3 " >
                  <i className="fa fa-bars"></i>
                  </button>
                  <img src={WEB_URL+"user_assets/img/logo_sharify.svg"} className="mobile_logo" />
               </nav>
               <div className="ctn_text_warp">
               <div className="container-fluid">
                     <div className="row">
                        <div className="col-md-12 mb-5">
                           {/* <div className="heading_text_ctn d-flex display_with justify-content-between">
                              
                               <h2><a href="">Payouts</a></h2>
                               <h2><a href="">Payment overview</a></h2>
                           </div> */}
                           <Tabs defaultActiveKey="Payment" id="uncontrolled-tab-example" className="tb-col">
                              <Tab eventKey="Payment" title="Auszahlungen">
                                 <div className="payment_text">
      <p>Dein derzeitiges Guthaben beträgt:  <span className="blue_color">{Revanue.currenttotal30} Euro</span>. Davon wurden <span className="blue_color">{Revanue.lasttotal30} Euro</span> vor mehr als 30 Tagen generiert. Die Auszahlung kann frühestens 30 Tage nach der Generierung und ab einem Betrag von 50 Euro beantragt werden.</p>
                                 <p>Bitte stelle dazu sicher, dass deine Rechnungsdaten und deine Bankverbindung korrekt sind!</p>
                                <p>Deine Auszahlung kann bis zu 30 Tage in Anspruch nehmen!</p>
                                    <div className="btn_request">
                                    <ConfirmLink action={() => handlePayout()} confirmMessage="Bist du dir sicher, dass du die Auszahlung beantragen möchtest?" confirmText="Ja" cancelText="Nein">
                                    <a href="javascript:void(0)" >Auszahlung beantragen!</a>
                      </ConfirmLink>
                                    </div>
                                 </div>
                                 <form key={1} className="title-description" onSubmit={handleSubmit(onSetupOne)}>

                              <div className="row">
                                 <div className="col-md-4">                                
                                       <div className="payment_sec_pym">
                                          <h2>Rechnungsdaten</h2>
                                          
                                          <div className="row">
                                                <div className="col-md-12">
                                                <div className="form-group">
                                                    
                                                    <input 
                                                    name="company"                                                    
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Name / Firmenname" 
                                                    onChange={handleChange} 
                                                    onChange={handleChange('company')}
                                                    aria-describedby="input"
                                                    defaultValue={company}
                                                    ref={register({ required: { value: true, message: "dieses Feld ist erforderlich" } })}
                                                    
                                                    />
                                                     <p className="text-danger error-text" >{errors?.company?.message}</p>
                                                </div>
                                                </div>
                                                <div className="col-md-12">
                                                <div className="form-group">
                                                    <input 
                                                    name="company_address"
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Adresse / Firmenanschrift" 
                                                    onChange={handleChange} 
                                                    onChange={handleChange('company_address')}
                                                    aria-describedby="input"
                                                    defaultValue={company_address}
                                                    ref={register({ required: { value: true, message: "dieses Feld ist erforderlich" } })}
                                                    
                                                    />
                                                    <p className="text-danger error-text" >{errors?.company_address?.message}</p>
                                                </div>
                                                </div>
                                                <div className="col-md-12">
                                                <div className="form-group">
                                                    <input 
                                                    name="additional_optional" 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Adresszeile 2"
                                                    onChange={handleChange} 
                                                    onChange={handleChange('additional_optional')}
                                                    aria-describedby="additional_optional"
                                                    defaultValue={additional_optional}
                                                    ref={register({ required: false })}
                                                    />
                                                </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="form-group">
                                                    <input 
                                                    name="postal_code" 
                                                    type="number"
                                                    min="1" 
                                                    className="form-control" 
                                                    placeholder="PLZ"
                                                    onChange={handleChange} 
                                                    onChange={handleChange('postal_code')}
                                                    aria-describedby="input"
                                                    defaultValue={postal_code}
                                                    ref={register({ required: { value: true, message: "PLZ ist erforderlich" } })}
                                                    />
                                                    <p className="text-danger error-text" >{errors?.postal_code?.message}</p>
                                                </div>
                                                </div>
                                                <div className="col-md-6">
                                                <div className="form-group">
                                                    <input 
                                                    name="city" 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Ort"
                                                    onChange={handleChange} 
                                                    onChange={handleChange('city')}
                                                    aria-describedby="input"
                                                    defaultValue={city}
                                                    ref={register({ required: { value: true, message: "Ort ist erforderlich" } })}
                                                    />
                                                    <p className="text-danger error-text" >{errors?.city?.message}</p>
                                                </div>
                                                </div>
                                                <div className="col-md-12">
                                                <div className="form-group">
                                                    <input 
                                                    name="country" 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Land" 
                                                    onChange={handleChange} 
                                                    onChange={handleChange('country')}
                                                    aria-describedby="input"
                                                    defaultValue={country}
                                                    ref={register({ required: { value: true, message: "Land ist erforderlich" } })}
                                                    />
                                                    <p className="text-danger error-text" >{errors?.country?.message}</p>
                                                </div>
                                                </div>
                                            </div>
                                        
                                       </div>
                                 </div>

                                 <div className="col-md-4">
                                <div className="payment_sec_pym">
                                    <h2>Bankverbindung</h2>
                                    
                                        <div className="row">
                                            <div className="col-md-12">
                                            <div className="form-group">
                                                <input 
                                                name="account_holder" 
                                                type="text"
                                                className="form-control" 
                                                placeholder="Kontoinhaber"
                                                onChange={handleChange} 
                                                autoComplete="on"
                                                onChange={handleChange('account_holder')}
                                                aria-describedby="account_holder"
                                                defaultValue={account_holder}
                                                ref={register({ required: { value: true, message: "Der Kontoinhaber ist erforderlich" } })}
                                                
                                                />
                                                <p className="text-danger error-text" >{errors?.account_holder?.message}</p>
                                            </div>
                                            </div>
                                            <div className="col-md-12">
                                            <div className="form-group">
                                                <input 
                                                name="iban"
                                                type="text"
                                                className="form-control" 
                                                placeholder="IBAN" 
                                                onChange={handleChange}
                                                autoComplete="on" 
                                                onChange={handleChange('iban')}
                                                aria-describedby="iban"
                                                defaultValue={iban}
                                                ref={register({
                                                   required: "IBAN erforderlich",
                                                   validate: isIban
                                                 })}
                                                />
                                                <p className="text-danger error-text" >{errors?.iban?.message}{errors.iban && errors.iban.type === "validate" && "IBAN ungültig"}</p>
                                            </div>
                                            </div>
                                            <div className="col-md-12">
                                            <div className="form-group">
                                                <input
                                                name="bic" 
                                                type="text"
                                                className="form-control" 
                                                placeholder="BIC"
                                                onChange={handleChange}
                                                autoComplete="on" 
                                                onChange={handleChange('bic')}
                                                aria-describedby="bic"
                                                defaultValue={bic}
                                                ref={register({
                                                   required:"BIC erforderlich",
                                                   validate: isBic
                                                 })}
                                                />
                                                <p className="text-danger error-text" >{errors?.bic?.message}{errors.bic && errors.bic.type === "validate" && "BIC ungültig"}</p>
                                            </div>
                                            </div>
                                        </div>
                                    
                                </div>
                                </div>

                                <div className="col-md-4">
                            <div className="payment_sec_pym tax_sec">
                                <h2>Steuerinformationen</h2>
                                <p>Ich bin...</p>
                                
                                <div className="py-2 collapse-inner rounded">
                                    <select 
                                    className="form-control" 
                                    name="tax_information_person" 
                                    value={tax_information_person}
                                    ref={register({ required: true })}
                                    onChange={handleChange('tax_information_person')}
                                    >

                                         <option disabled="disabled">Bitte auswählen</option>

                                        <option value="1">Privatperson, unternehmerisch tätig</option>
                                        <option value="2">Kleinunternehmer, gemäß §19 UStG</option>
                                        <option value="3">Regelunternehmer, USt Pflichtig </option>

                                      
                                    </select>
                                </div>
                                    <p className="mt-3 mb-3">
                                    <span className="blue_color">{tax_bottom_text}</span>
                                    </p>
                                    
                                        <div className="row">
                                            <div className="col-md-12 mt-4 mb-4">
                                                <div className="form-group">
                                                    <input
                                                    name="tax_number" 
                                                    type="text" 
                                                    className="form-control" 
                                                    placeholder="Steuernummer/UST-ID"
                                                    autoComplete="on"
                                                    onChange={handleChange} 
                                                    onChange={handleChange('tax_number')}
                                                    aria-describedby="tax_number"
                                                    defaultValue={tax_number}
                                                    ref={register({ required: { value: true, message: "die Steuernummer ist erforderlich" } })}
                                                    />
                                                    <p className="text-danger error-text" >{errors?.tax_number?.message}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="form-check">
                                                    <input type="checkbox" name="confirm" autoComplete="on" className="form-check-input" id="exampleCheck1" 
                                                     ref={register({ required: { value: true, message: "Bitte bestätige, dass deine Angaben korrekt sind." } })}
                                                    />

                                                    <label className="form-check-label" for="exampleCheck1">Hiermit bestätige ich, dass die von mir gemachten Angaben der Wahrheit entsprechen.</label>
                                                    <p className="text-danger error-text" >{errors?.confirm?.message}</p>

                                                </div>
                                            </div>
                                            <div className="col-md-12 text-right mt-3 mb-5">
                                                <div className="btn_request">
                                                    <button type="submit" href="">Speichern!</button>
                                                </div>
                                            </div>
                                        </div>                                
                                </div>                  
                            </div> 
                              </div>
                              </form>
                              
                              </Tab>
                              <Tab eventKey="Payouts" title="Zahlungsübersicht">
                              <div className="payment_text shadow table_pyt">
                                    <div className="table-responsive">
                                    
                                       <table className="table">
                                          <thead>
                                             <tr>
                                                <th scope="col">Datum</th>
                                                <th scope="col">Betrag</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Gutschrift</th>
                                             </tr>
                                          </thead>
                                          <tbody>
                                          {payoutRequests.map((payoutRequest,i)=>(
                                             
                                                <tr>
                                                   <td>{Moment(payoutRequest.createdOn).format('DD.MM.YYYY')}</td>
                                                   <td>{payoutRequest.amount}</td>
                                                   <td>{(payoutRequest.withdrawStatus==0) ? 'Unbezahlt':'Bezahlt'}</td>
                                                   <td><a href={"/creditNote/"+payoutRequest._id} target="_blank">Download</a></td>
                                                </tr>
                                             
                                          ))}
                                          
                                       
                                             
                                          </tbody>
                                       </table>
                                    </div>
                              </div>
                              </Tab>
                           </Tabs>

                          
                        </div>
                        <div className="col-md-12 text-right">
                           <p className="mb-0 copy_right">©2021 S&amp;D Media Management UG (haftungsbeschränkt).
Alle Rechte vorbehalten.</p>
                        </div>
                        
                     </div>
                  </div>
               </div>
            </div>
          </div>
        </div>
        
        </main>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>

        
        
        </Fragment>
        
 )
}

export default UserPayment;