import React, { Fragment } from "react";

import Sidebar from  './Sidebar';
import {WEB_URL,API_UPLOAD_PATH} from '../../constants';
import {Link} from 'react-router-dom';





import { Form, Button, Alert } from "react-bootstrap";

import { updateprofile,isAuthenticated,authenticate,logout } from "../../actions/auth";





// const token  = localStorage.getItem('token');





const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};





class AdminSetting extends React.Component {
  // static contextType = AuthContext;

 

  
  
  constructor(props) {
    super(props);
    this.state = {
      username: null,
      password: null,
      confirm_password: null,
      old_password:null,
      agree: false,
      token:"",
      errors: {
        username: "",
        password: "",
        confirm_password: "",
        old_password:"",
        agree: false,
      },

      errors2: []
    };

    
    

   

   
  }

componentDidMount()
{
  const {user} = isAuthenticated();

  this.setState({
    username: user.username,
    token: user.token,
  });
  if(window.innerWidth<480){
       document.getElementById("sidebarToggleTop").click();
 
    }
}


  handleChange = (event) => {
    const { name, value } = event.target;
    
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "username":
        errors.username =
          value.length < 5 ? "Der Username muss mind. 5 Zeichen lang sein!" : "";
        break;
      case "password":
        errors.password =
          (value.length < 8  && value.length > 0) ? "Das Passwort muss mind. 8 Zeichen lang sein!" : "";
        break;
      case "confirm_password":
        errors.confirm_password =
          value != this.state.password ? "Die Passwörter stimmen nicht überein!" : "";
        break;
        case "old_password":
        errors.old_password =
        (value.length < 8  && value.length > 0)  ? "Das Passwort muss mind. 8 Zeichen lang sein!" : "";
        break;
      default:
        break;
    }
  };

  


  handleSubmit = (event) => {
    event.preventDefault();
    var sed = true;
    let userName = (event.target.username) ? (event.target.username.value) : ('');
 
    this.state.username = userName;

    
    

    if (this.state.password != null)
     {

      if(this.state.confirm_password == null && this.state.old_password==null)
      {
        this.setState({
          msg: "Bitte alle Felder ausfüllen",
          alert: "danger",
        });

        sed = false;
      }
    }

   
    
    if (
      validateForm(this.state.errors)  &&
      sed === true
    ) {
      

      const { username,password,old_password ,token} = this.state;


      const newUser = {
        old_password,
        username,
        password,
        token
      };

      //

      updateprofile(newUser, (res) => {
        if (res.data.success) {
          
          authenticate(res.data,()=>{
            this.setState({
              msg: res.data.msg,
              alert: "success",
            });
          });

          

          window.location.reload(false);

        } else {
          this.setState({
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    } else
     {
      this.setState({
        msg: "Bitte alle Felder ausfüllen",
        alert: "danger",
      });
    }
  };

  

  render() {

    const { errors } = this.state;

    const {username} = this.state;

   


    
    return (
     
     
        <Fragment>
          <main className="admin_dashboard_panel">
        <div id="wrapper"  >
         <Sidebar/>

          <div id="content-wrapper" className="d-flex flex-column ">
            <div id="content" >
              <nav className="navbar navbar-expand navbar-light topbar  static-top">
                <button
                  id="sidebarToggleTop"
                  className="btn btn-link d-md-none rounded-circle mr-3"
                >
                  <i className="fa fa-bars"></i>
                </button>
                <img src={WEB_URL+"admin_assets/img/logo_sharify.svg"} className="mobile-logo" />
              </nav>
              <div className="ctn_text_warp">
                <div className="box_top_bar d-flex align-items-center justify-content-between">
                     <div className="search d-flex align-items-center">
                        <p className="mb-0 ml-2">Einstellungen</p>
                     </div>
                     <div className="ctn_logout float-right">
                     <Link to="" onClick={() => logout()}>Abmelden</Link>
                     </div>
                </div>
              <form className="pym_form" method="post" id="setting"  onSubmit={this.handleSubmit}  noValidate>
                <div className="container-fluid">
                  <div className="row">
                  
                    
                    <div className="col-md-12 mt-5">
                      <div className="heading_text_ctn display_with">
                      {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                        
                        

                      
                  
                        
                      </div>
                    </div>
                    <div className="col-md-6 pr-5">
                      <div className="payment_sec_pym">
                        <h2>Passwort ändern</h2>
                       
                            
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                type="password"
                                className="form-control"
                                placeholder="Neues Passwort"
                                name="password"
                                autoComplete="on"
                                onChange={this.handleChange}
                                noValidate
                              />
                              {errors.password.length > 0 && (
                                <span className="error">{errors.password}</span>
                              )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Passwort wiederholen"
                                  name="confirm_password"
                                autoComplete="on"
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.confirm_password.length > 0 && (
                                  <span className="error">
                                    {errors.confirm_password}
                                  </span>
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="password"
                                  className="form-control"
                                  placeholder="Altes Passwort"
                                  name="old_password"
                                autoComplete="on"
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.old_password.length > 0 && (
                                  <span className="error">
                                    {errors.old_password}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="payment_sec_pym">
                        <h2>Username</h2>
                      
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                              <input
                                  type="text"
                                  className="form-control"
                                  name="username"
                                  placeholder="Username"
                                  defaultValue={username}
                                  autoComplete="on"
                                  onChange={this.handleChange}
                                  noValidate
                                />
                                {errors.username.length > 0 && (
                                  <span className="error">{errors.username}</span>
                                )}
                              </div>
                            </div>
                          </div>
                       
                      </div>
                      <div className="payment_sec_pym email_text">
                       
                          <div className="row">
                            
                            <div className="col-md-12 text-right mt-3 mb-2">
                              <div className="btn_request">

                                <button
                                  type="submit"
                                  className="btn_submit"
                                  value="Submit"
                                 >
                                  Speichern
                                </button>
                                {/* <a href="" type="submit">Save</a> */}
                              </div>
                            </div>
                          </div>
                        
                      </div>
                    </div>
                    
                  
                    <div className="col-md-12 text-right mt-5">
                    <p className="mb-0 copy_right">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
                    </div>
                  </div>
                  
              
                </div>
                </form>
              </div>
              
            </div>
          </div>
        </div>
        </main>

        <a className="scroll-to-top rounded" href="#page-top">
          <i className="fas fa-angle-up"></i>
        </a>
        </Fragment>
 
    );
  }
}
export default AdminSetting;
