import React, { Component } from "react";

import { Form, Button, Alert } from "react-bootstrap";
import { useParams } from "react-router-dom";
import "../user_assets/css/auth-style.css";

import { resetpassword } from "../actions/auth";
import { AuthContext } from '../contexts/AuthContext';

import logo from '../user_assets/img/logo_sharify.svg';

import queryString from 'query-string';

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};



class ChangePassword extends Component {

  static contextType = AuthContext;

 
  
  constructor(props) {
    
    super(props);
    this.state = {
      token:  this.props.match.params.token,
      password: null,
      confirm_password: null,
      errors: {
        password: "",
        confirm_password: ""
      },
      errors2: []
    };

   

    
    

   


  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "password":
        errors.password =
          value.length < 8 ? "Das Passwort muss mind. 8 Zeichen lang sein!" : "";
        break;
      case "confirm_password":
        errors.confirm_password =
          value != this.state.password ? "Die Passwörter stimmen nicht überein!" : "";
        break;
      default:
        break;
    }
  };

 

  componentDidMount(){
    if(this.context.isAuthenticated){
      this.props.history.push('/')
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    var sed = true;


    if (
      this.state.password == null ||
      this.state.confirm_password == null
    ) {
      this.setState({
        msg: "Bitte alle Felder ausfüllen",
        alert: "danger",
      });
      sed = false;
    }

   
    
    if (
      validateForm(this.state.errors) &&
      sed === true
    ) {
      

      const { password } = this.state;

     
const  { token } = this.state;

       const newUser = {
        password,
        token
       
      };

      //

      resetpassword(newUser, (res) => {
        if (res.data.success) {
          this.setState({
            msg: res.data.msg,
            alert: "success",
          });

          this.props.history.push('/password-changed')
          
          // console.log(res);
        

        } else {
          this.setState({
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    } 
  };

 
   
  

  render() {
    const { errors } = this.state;

    return (
      <section className="sharify_user">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <div className="sharify_center_user">
                <div className="text_user text-center">
                  <div className="logo">
                    <img src={logo} />
                  </div>
                  <p>Neues Password eingeben</p>

                  {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                </div>
                <form
                  className="reset-password"
                  onSubmit={this.handleSubmit}
                  noValidate
                >
                  <div className="row">
                    
                   
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Passwort"
                          name="password"
                          autoComplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.password.length > 0 && (
                          <span className="error">{errors.password}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          autoComplete="on"
                          className="form-control"
                          placeholder="Passwort wiederholen"
                          name="confirm_password"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.confirm_password.length > 0 && (
                          <span className="error">
                            {errors.confirm_password}
                          </span>
                        )}
                      </div>
                    </div>
                 
                    <div className="col-md-12 text-center ">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn_submit"
                          value="Submit"
                        >
                        Confirm
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12 text-right">
              <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default ChangePassword;
