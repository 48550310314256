import React, { Component} from "react";

import { Redirect } from "react-router-dom";
import "../user_assets/css/auth-style.css";




import { Form, Button, Alert } from "react-bootstrap";

import { login ,isAuthenticated,authenticate } from "../actions/auth";

import { createBrowserHistory } from 'history';
const history = createBrowserHistory({forceRefresh:true});

const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach((val) => val.length > 0 && (valid = false));
  return valid;
};


class Login extends React.Component {






  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      email: "",
      password: "",
      errors: {
        email: "",
        password: ""
      },
      redirectToReferrer:false,
      role:""
    };
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    let errors = this.state.errors;
    this.setState({ errors, [name]: value });
    switch (name) {
      case "email":
        errors.email = validEmailRegex.test(value) ? "" : "Die Email ist ungültig!";
        break;
      case "password":
        errors.password =
          value.length < 8 ? "Das Passwort muss mind. 8 Zeichen lang sein!" : "";
        break;
      default:
        break;
    }
  };

  


  handleSubmit = (event) => {
    event.preventDefault();


    var sed = true;

    if (
      this.state.email == null ||
      this.state.password == null 
    ) {
      this.setState({
        msg: "Bitte alle Felder ausfüllen",
        alert: "danger",
      });
      sed = false;
      this.setState({
        isLoading: false
      });
    }

    
    if (
      validateForm(this.state.errors) &&  sed === true
      )
      {
      

      const { email, password } = this.state;

      const newUser = {
        email,
        password,
      };

      //
      this.setState({ isLoading: true });
      

      login(newUser, (res) => {
       
        if (res.data.success) {
        
         
          //  this.context.login(res);

       
            

            authenticate(res.data,()=>{
              this.setState({
                msg: res.data.msg,
                alert: "success",
                redirectToReferrer:true,
                isLoading:false,
                role:res.data.user.role
              });
            });


        

            if(res.data.user.role==1){
              this.props.history.push('admin/dashboard');

            }else{
              this.props.history.push('user/dashboard');

            }

          

        } else {
          this.setState({
            isLoading:false,
            msg: res.data.msg,
            alert: "danger",
          });
        }
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  };


  

  render() {
    const { errors } = this.state;
   
   
    return (
      
      <section className="sharify_user">
        <div className="container">
          <div className="row">
            <div className="col-md-7 mx-auto">
              <div className="sharify_center_user login-page">
                <div className="text_user text-center">
                  <div className="logo">
                    <img src="assets/images/logo_sharify.svg" />
                  </div>
                  <p>Willkommen, bitte logge dich ein!</p>
                  {this.state.msg ? (
                    <Alert variant={this.state.alert}>{this.state.msg}</Alert>
                  ) : null}
                </div>
                <form
                  className="login"
                  method="post"
                  onSubmit={this.handleSubmit}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Email"
                          name="email"
                          autocomplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.email.length > 0 && (
                          <span className="error">{errors.email}</span>
                        )}
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group">
                        <input
                          type="password"
                          className="form-control"
                          placeholder="Passwort"
                          name="password"
                          autocomplete="on"
                          onChange={this.handleChange}
                          noValidate
                        />
                        {errors.password.length > 0 && (
                          <span className="error">{errors.password}</span>
                        )}
                      </div>
                      <label className="forget_password"><a href="#" onClick={() => this.props.history.push('/forgot-password')}  >Vergessen?</a></label>
                    </div>

                    <div className="col-md-12 text-center ">
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn_submit"
                          value="Submit"
                          disabled={this.state.isLoading}
                        >
                          {this.state.isLoading ? "Laden..." : "Einloggen"}
                        </button>
                      </div>
                      <a className="mb-0" href="#" onClick={() => this.props.history.push('/register')} >Noch keinen Account? Erstellen!</a>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-md-12 text-right">
            <p className="mb-0">©2021 S&amp;D Media Management UG (haftungsbeschränkt).<br></br>Alle Rechte vorbehalten.</p>
            </div>
          </div>
        </div>
      </section>
    );
  
  }
}
export default Login;
