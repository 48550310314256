import React, { Fragment, useCallback, useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import { Link } from "react-router-dom";
import { logout, isAuthenticated } from "../../actions/auth";
import { WEB_URL, API_UPLOAD_PATH} from "../../constants";
import {sendaddcampignnotification,sendNotifactionArchived,upload,addCamgian,campiganList,updateCamgian,mediaListByCampign,deleteMedia,uploadUpdate,mediaList,ArchivedCampiagn,campiganArchivedList,updateStatusCampign} from "../../actions/AdminApi";

import { Modal, Tab, Tabs } from "react-bootstrap";
import { Nav } from "react-bootstrap";
import  Moment from 'moment';
import { get, useForm } from "react-hook-form";
import { useAlert } from 'react-alert';
import ConfirmLink from 'react-confirm-dialog';
import ReactPaginate from "react-paginate";



 
const CampaingListing = () => {
  const alert = useAlert()

  const { register, handleSubmit, watch, errors } = useForm();




  const {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2
  } = useForm();


  



  const { user } = isAuthenticated();
  const [media , setMedia] =  useState()

  var currentPage = 0;

const [data, setData] = useState([]);





const [perPage,SetPerpage] = useState(6);
// const [currentMedia,setCurrentMedia] = useState(0);



const [pageCount, setPageCount2] = useState(0);

  const [story, setStory] = useState([]);
  const [show, setShow] = useState(false);

  const [isUpdate, setIsUpdate] = useState(false);



  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [campaings , setCampaings] =  useState([]);
  const [archivedcampaings , setArchivedCampaings] =  useState([]);


  const [activekey, setActive] = useState("briefing");

  const [values, setValues] = useState({
     id:"",
    title: "",
    banner: [],
    profile: [],
    description: "",
    briefing: "",
    linkAndroid:"",
    linkIos:"",
    max_availble_downloads: "",
    user_per_download: "",
    sharify_get_download: "",
    campaigns_from_date: "",
    campaigns_till_date: "",
    stories: [],
    feeds: [],
    loading: false,
  });

  const {
     id,
    title,
    banner,
    linkAndroid,
    linkIos,
    profile,
    description,
    briefing,
    max_availble_downloads,
    user_per_download,
    sharify_get_download,
    campaigns_from_date,
    campaigns_till_date,
    stories,
    feeds,
    loading,
  
   
  } = values;

  const token = user.token;

  var hideInput = {
    display: "none",
  };

  var poniter = {
   cursor: "pointer"
  }

  const init = () => {
   const Userdata = {
      token
    };

   
   campiganList(Userdata,(res) => {
      if(res.data.status){
         setCampaings(res.data.data)
      }
    });


    campiganArchivedList(Userdata,(res) => {
      if(res.data.status){
        setArchivedCampaings(res.data.data)
      }
    });

    


};





  const changeActive = (key) => {
    setActive(key);
  };

  const handelFiles = async (e) => {
    var attName = e.target.name.replace(/[\[\]']+/g, "");

    var files = [];
    files = e.target.files;

    var formDatas = new FormData();

    for (const file of files) {
      

      formDatas.append(attName, file);
    }


    
if(isUpdate){
  //SetPerpage(6);
  var camid = id;
  const newUser = {
    formDatas,
    token,
    attName,
    camid
  };

  uploadUpdate(newUser, (res) => {

    if (res.data.status) {
      getData(camid,0);
      setValues({ ...values, [attName]: res.data.data });
     alert.success('Erfolgreich aktualisiert');

    }
  });

}else{
  var cm = 0;
  var n = files.length;

  if(attName==='stories' || attName==='feeds'){

    if(localStorage.getItem('crt')){
       var ct = localStorage.getItem('crt');
       cm = parseInt(ct)+parseInt(n);
    }else{
      cm = n;
    }
    localStorage.setItem('crt',cm);
  
  }

  const newUser = {
    formDatas,
    token,
    attName,
  };

  upload(newUser, (res) => {
    if (res.data.status) {


     if(attName==='stories' || attName==='feeds'){
      getData(false,0,cm);

     }
      setValues({ ...values, [attName]: res.data.data });
      alert.success('Erfolgreich hochgeladen!');
    }
  });
}
    

    


  };

  const archivedCampiagn = (campaign) =>{
    var campid = campaign._id;
    var title = campaign.title;
    
    const newUser = {
      campid,
      token
    };
    ArchivedCampiagn(newUser, (res) => {
      if (res.data.success) {
       const payload ={
        token,
        title

       }
        sendNotifactionArchived(payload, (res) => {

        });
        init();
        alert.success('Kampagne archiviert');
      }
    });
  }

  const updateCampiagnStatus = (campaign) =>{
    var campid = campaign._id;
    
    const newUser = {
      campid,
      token
    };
    updateStatusCampign(newUser, (res) => {
      if (res.data.success) {
        init();
        alert.success(res.data.msg);
      }
    });
  }

  

  useEffect(() => {
         init();
         if(window.innerWidth<480){
             document.getElementById("sidebarToggleTop").click();
       
          }
  },[]);


  const getCampign = (cid) =>
  {
    
     var selectedCampign =[];
     selectedCampign=campaings[cid];
  var   b=selectedCampign['banner'];
  var   p=selectedCampign['profile'];
  var id = selectedCampign['_id'];


setIsUpdate(true);

   setValues({
      ...values,
    
      id:selectedCampign['_id'],
      title: selectedCampign['title'],
      description: selectedCampign['description'],
      briefing: selectedCampign['briefing'],
      linkAndroid:selectedCampign['linkAndroid'],
      linkIos:selectedCampign['linkIos'],
      banner:[b],
      profile:[p],
      max_availble_downloads: selectedCampign['max_availble_downloads'],
      user_per_download: selectedCampign['user_per_download'],
      sharify_get_download: selectedCampign['sharify_get_download'],
      campaigns_from_date: Moment(selectedCampign['campaigns_from_date']).format('YYYY-MM-DD'),
      campaigns_till_date: Moment(selectedCampign['campaigns_till_date']).format('YYYY-MM-DD')
      
      });



    changeActive("briefing");

   
    getData(id,0);
     handleShow()

  }

  const addModal = () => {
    localStorage.setItem('crt',0);
    setIsUpdate(false);
    
   setValues({
      ...values,
     
      id:"",
      title: "",
      banner: [],
      profile: [],
      description: "",
      briefing: "",
      linkAndroid:"",
      linkIos:"",
      max_availble_downloads: "",
      user_per_download: "",
      sharify_get_download: "",
      campaigns_from_date: "",
      campaigns_till_date: "",
      stories: [],
      feeds: [],
      loading: false
     
      });
      
      setMedia(false);

      changeActive("briefing");
      

      
     handleShow()
  


  };


  const handleChange = (name) => (event) => {
    const value = event.target.value;
    setValues({ ...values, [name]: value });

  };

  const onSetupOne = data =>{
    
    changeActive("Media");
  }

  const priceSection = () =>{
  
    changeActive("Pricetable");
  }
  

  const onSubmit = data => {

   
   
      const formdata = {
         id,
         title,
         banner,
         profile,
         description,
         briefing,
         linkAndroid,
         linkIos,
         max_availble_downloads,
         user_per_download,
         sharify_get_download,
         campaigns_from_date,
         campaigns_till_date,
         stories,
         feeds,
         token
       
         
    }



    if(!isUpdate){
      addCamgian(formdata, (res) => {
         if (res.data.status) {
            init();
            sendaddcampignnotification(formdata, (res) => {
            });
            
     alert.success('Erfolgreich erstellt');

            handleClose()

         }
       });
    }else{
      updateCamgian(formdata, (res) => {
         if (res.data.status) {
            init();
setIsUpdate(false);
alert.success('Erfolgreich aktualisiert');

           
            handleClose()


         }
       });
    }
   

    
    
    

    
  };


  const deletemedia = (obj) =>{

    var cm = localStorage.getItem('crt');

    localStorage.setItem('crt',cm-1)

    var mediaid = obj._id;

    const newUser = {
       mediaid,
       token 
     };

     //

     deleteMedia(newUser, (res) => {
       if (res.data.success) {
        var id = obj.campaign;
        getData(id,0,cm-1);


       
       } 
     });


 }
 


 const getData = async(id,currentPage,n=false) => {


    const offset = currentPage * perPage;

    const fdata ={
      token,
      id, n
    }

    if(!id){
  

    mediaList(fdata,(res) => {
      if(res.data.status){

        const data2 = res.data.data;

     const data =  data2.slice(0, n);


        const slice = data.slice(offset, offset + perPage)
        const postData = slice.map((me,i)=>(
          
  
          <div className="col-md-2" key={me._id}>
          <div className="box_legends" >
             {(me.media=='video') ? ( <video   controls>
  <source src={API_UPLOAD_PATH + me.link} /> </video> ):(<img src={API_UPLOAD_PATH + me.link}/>)}
         
           
            <div className="delet">
              <a href="#"  onClick={() => deletemedia(me)}><img
                src={
                  WEB_URL +
                  "admin_assets/img/svg/Icon-delete.svg"
                }
              /></a>
            </div>
          </div>
          </div>
          ))
          setMedia(postData);
        setPageCount2(Math.ceil(data.length / perPage))
      }
    });

  }else{

    mediaListByCampign(fdata,(res) => {
      if(res.data.status){
        const data = res.data.data;
        const slice = data.slice(offset, offset + perPage)
        const postData = slice.map((me,i)=>(
          
  
          <div className="col-md-2" key={me._id}>
          <div className="box_legends" >
             {(me.media=='video') ? ( <video   controls>
  <source src={API_UPLOAD_PATH + me.link} /> </video> ):(<img src={API_UPLOAD_PATH + me.link}/>)}
         
           
            <div className="delet">
              <a href="#"  onClick={() => deletemedia(me)}><img
                src={
                  WEB_URL +
                  "admin_assets/img/svg/Icon-delete.svg"
                }
              /></a>
            </div>
          </div>
          </div>
          ))
          setMedia(postData);
        setPageCount2(Math.ceil(data.length / perPage))
      }
    });
   

  }
  
   
}

// const handlepage = async (value) => {
//  setCurrentPage(value);

//  return await currentPage;
// }

// const handleSetState =  async (stateAction) => {


// }




const handlePageClick = async ({ selected: selectedPage }) => {


  var cm =localStorage.getItem('crt');

     if(id){
    getData(id,selectedPage)
  }else{
    getData(false,selectedPage,cm)
    
  }
 


  


 }
// async function handlePageClick({ selected: selectedPage }) {
   
//   console.log(currentPage);
//   await setCurrentPage(selectedPage);

//   if(id){
//     getData(id)
//   }else{
//     getData()
    
//   }
 
// }

    
  

  const showMedia = () =>{

    if(media){
    return <div className="row">
      <div class="col-md-12 mt-5">
            <h2>Medien</h2>
      </div>
      <div className="row">
      {media}
       </div>  
       <div class="col-md-12 text-right mt-5">
         <div className="content_detail__pagination cdp">
           
       <ReactPaginate
        previousLabel={"Prev"}
        nextLabel={"Next"}
        pageCount={pageCount}
        onPageChange={handlePageClick}
        containerClassName={"pagination "}
        previousLinkClassName={"pagination__link cdp_i  "}
        nextLinkClassName={"pagination__link  cdp_i "}
        pageLinkClassName={"cdp_i"}
        disabledClassName={"pagination__link--disabled  "}
        activeClassName={"pagination__link--active  active"}
        activeLinkClassName={"active"}
        breakLinkClassName={"break"}
        
      />
    </div>


        </div>   

         </div>
    }

   
  };

     
    
    

  return (
<Fragment>
      <main className="admin_dashboard_panel">
      <div id="wrapper"  >
        <Sidebar />
        <div id="content-wrapper" className="d-flex flex-column">
          <div id="content">
            <nav className="navbar navbar-expand navbar-light topbar static-top " >
              <button
                id="sidebarToggleTop"
                className="btn btn-link d-md-none rounded-circle mr-3"
              >
                <i className="fa fa-bars"></i>
              </button>
              <img
                src={WEB_URL + "admin_assets/img/logo_sharify.svg"}
                className="mobile-logo"
              />
            </nav>
            <div className="ctn_text_warp" >
              <div className="box_top_bar d-flex align-items-center justify-content-end">
                 
                 <div className="search d-flex align-items-center float-left">
                       
                     </div>
                <div className="ctn_logout float-right">
                  <Link to="" onClick={() => logout()}>
                    Abmelden
                  </Link>
                </div>
              </div>
             
              <div className="container-fluid p-0">
                <div className="row p-4">
                  <div className="col-md-12">
                      <Tabs defaultActiveKey="Campaings" id="uncontrolled-tab-example" className="tb-col">

                      
                          <Tab eventKey="Campaings" title="Kampagnen">
                          <div className="Your_champ mt-3">
                      <div className="row">
                      {campaings.map((campain,i)=>(
                                    
                                    
                                 
                             
                        <div className="col-md-6 mt-3" key={i}>
                          <div>
                            <div className="box_camp shadow">
                              <div className="logo_box text-center" height="125px">
                                <img
                                  src={
                                    (campain.banner != null && campain.banner != 'undefined') ? API_UPLOAD_PATH + campain.banner:""
                                  }
                                />
                                 <ConfirmLink  action={() => archivedCampiagn(campain)} confirmMessage="Möchtest du die Kampagne wirklich archivieren? Dies kann nicht rückgängig gemacht werden." confirmText="Ja" cancelText="Nein">
                                 <div className="close_icon" style={poniter}    >
                                  <img
                                    src={
                                      WEB_URL +
                                      "admin_assets/img/svg/Icon-material-close.svg"
                                    }
                                  /> </div>   
                                  
                                    </ConfirmLink>
                               
                                
                                <div className="edit_icon" style={poniter} onClick={() => getCampign(i)}>
                                  <img
                                    src={
                                      WEB_URL + "admin_assets/img/svg/edit.svg"
                                    }
                                  />
                                </div>
                              </div>
                              <div className="box_champ_tt">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="champ_pyc">
                                      <img
                                        src={
                                          (campain.profile != null && campain.profile != 'undefined') ? API_UPLOAD_PATH + campain.profile:""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                    <div className="heading_tab">
                                       <h2>{campain.title}</h2>
                                      <div className="progress">
                                        <div className="bar" style={{width:campain.calculations.aviable_download+"%"}}></div>
                                      </div>
                                      <p className="mb-0">{campain.calculations.aviable_download}% verfügbar</p>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="right_price">
                                      <h4>Einnahme/Download</h4>
                                      <h2 className="text-center">{campain.user_per_download.toString().replace('.',',')}€</h2>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-0">
                                    <p className="mb-0 clam_raid">
                                      {campain.briefing}
                                    </p>
                                  </div>

                                 
                                   
                      
                                  <div className="col-md-12 text-right">
                                  <ConfirmLink action={() => updateCampiagnStatus(campain)} confirmMessage="Möchtest du den Status der Kampagne ändern?" confirmText="Ja" cancelText="Nein">
                                    <img
                                      src={
                                       
                                        (campain.status==1) ?  WEB_URL +'admin_assets/img/svg/pause.svg':  WEB_URL +'admin_assets/img/svg/Icon-right_arrow.svg'
                                      }
                                      width="14px"
                                    />
                                  </ConfirmLink>
                                  </div>
                                 
                                  <div className="col-md-12">
                                    <div className="botm_last">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">Downloads</p>
                                            <div className="price price_divided">
                                    <p className="mb-2">{campain.calculations.linkDownloadCount}</p>
                                              <p className="mb-0 ml-5">
                                              {campain.max_availble_downloads}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">Klicks</p>
                                            <div className="price">
                                              <p className="mb-0">                                              {campain.calculations.linkClickCount}
</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">
                                              Verteilte Links
                                            </p>
                                            <div className="price">
                                              <p className="mb-0">{campain.calculations.linkdis}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                         ))}
                        
                      </div>
                    </div>
                          </Tab>
                          <Tab eventKey="Archived" title="Archiviert">
                          <div className="Your_champ mt-3">
                      <div className="row">
                      {archivedcampaings.map((campain,i)=>(
                                    
                                    
                                 
                             
                        <div className="col-md-6 mt-3" key={i}>
                          <div>
                            <div className="box_camp shadow">
                              <div className="logo_box text-center" height="125px">
                                <img
                                  src={
                                    (campain.banner != null && campain.banner != 'undefined') ? API_UPLOAD_PATH + campain.banner:""
                                  }
                                />
                               
                              </div>
                              <div className="box_champ_tt">
                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="champ_pyc">
                                      <img
                                        src={
                                          (campain.profile != null && campain.profile != 'undefined') ? API_UPLOAD_PATH + campain.profile:""
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-5">
                                  <div className="heading_tab">
                                       <h2>{campain.title}</h2>
                                      <div className="progress">
                                        <div className="bar" style={{width:campain.calculations.aviable_download+"%"}}></div>
                                      </div>
                                      <p className="mb-0">{campain.calculations.aviable_download}% verfügbar</p>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className="right_price">
                                      <h4>Einnahme/Download</h4>
                                      <h2 className="text-center">{campain.user_per_download.toString().replace('.',',')}€</h2>
                                    </div>
                                  </div>
                                  <div className="col-md-12 mt-0">
                                    <p className="mb-0 clam_raid">
                                      {campain.briefing}
                                    </p>
                                  </div>
                                  <div className="col-md-12 text-right">
                                    <img
                                      src={
                                        WEB_URL +
                                        "admin_assets/img/svg/Icon-right_arrow.svg"
                                      }
                                    />
                                  </div>
                                  <div className="col-md-12">
                                    <div className="botm_last">
                                      <div className="row">
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">Downloads</p>
                                            <div className="price price_divided">
                                              <p className="mb-2">{campain.calculations.linkDownloadCount}</p>
                                              <p className="mb-0 ml-5">
                                              {campain.max_availble_downloads}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">Klicks</p>
                                            <div className="price">
                                              <p className="mb-0">{campain.calculations.linkClickCount}</p>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-4">
                                          <div className="click_box">
                                            <p className="mb-4">
                                              Verteilte Links
                                            </p>
                                            <div className="price">
                                              <p className="mb-0">{campain.calculations.linkdis}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                         ))}
                        
                      </div>
                    </div>
                          </Tab>

                        
                      </Tabs>
                  </div>
                  <div
                    className="col-md-12 text-right mt-4"
                     onClick={() => addModal()}
                  >
                    <img src={WEB_URL + "admin_assets/img/svg/Add.svg"} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </main>

      <a className="scroll-to-top rounded" href="#page-top">
        <i className="fas fa-angle-up"></i>
      </a>

      <Modal  show={show} onHide={handleClose}  dialogClassName="modal-90w modal_section" centered="1">
         
            <div className="container-fluid p-0">
              <Tab.Container
                id="left-tabs-example"
                activeKey={activekey}
                onSelect={(k) => setActive(k)}
              >
                <div className="row no-gutters align-items-center">
                  <div className="col-md-3">
                    <div className="tab">
                      {/* <button className="tablinks nav-link" eventKey="briefing" >Title & Description</button>
                       */}
                      <Nav variant="pills" className="flex-column">
                        <Nav.Item>
                          <Nav.Link className="tablinks" eventKey="briefing">
                            Titel & Beschreibung
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="tablinks" eventKey="Media">
                            Medien
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link className="tablinks" eventKey="Pricetable">
                            Preisgestaltung
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>

                       
                    </div>
                  </div>
                  <div className="col-md-9">
                    <Tab.Content>
                      <Tab.Pane eventKey="briefing">
                        <div id="briefing" className="tabcontent shadow">
                          <form key={1} className="title-description" onSubmit={handleSubmit2(onSetupOne)}>
                            <div className="row">
                              <div className="col-md-12 mb-3">
                                <div className="form-group d-flex align-items-center">
                                  <label>Titel</label>
                                  <div className="input-outer">
                                  <input
                                    type="text"
                                    name="title"
                                    autoComplete="on"
                                    onChange={handleChange("title")}
                                    
                                    defaultValue={title}
                                    className="form-control"
                                    aria-describedby="input"
                                    ref={register2({ required: { value: true, message: "Titel ist erforderlich" } })}

                                  />
                                  <p className="text-danger error-text" >{errors2?.title?.message}</p>
                                  </div>
                                </div>

                               
                              


                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group position-relative d-flex align-items-center">
                                  <label>Banner:</label>
                                  
                                  <div className="input-outer">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="banner[]"
                                    onChange={handelFiles}
                                    
                                  //  ref={ register({ required: { value: true, message: "Banner is required" } })}
                                    accept="image/*"
                                    
                                  />
                                  <p className="text-danger error-text file-error-message"  >{errors2.banner && 'Banner ist erforderlich'}</p>
                                  {/* <p className="mb-0">Upload File</p> */}
                                </div>
                                </div>
                                

                              </div>
                              <div className="col-md-6 mb-3">
                                <div className="form-group position-relative d-flex align-items-center">
                                  <label>Profilbild:</label>
                                  <div className="input-outer">
                                  <input
                                    type="file"
                                    id="myfile"
                                    name="profile[]"
                                    accept="image/*"
                                    // ref={register({ required: { value: true, message: "Profile is required" } })}

                                    onChange={handelFiles}
                                  />
                                  {/* <p className="mb-0">Upload File</p> */}
                                  <p className="text-danger error-text file-error-message" >{errors2.profile &&  'Profilbild ist erforderlich'}</p>
                                  </div>
                                </div>
                               

                              </div>
                              <div className="col-md-12 mb-3">
                                <div className="form-group d-flex align-items-center">
                                  <label className="">Beschreibung</label>
                                  <div className="input-outer">
                                  <textarea
                                    rows="7"
                                    tabIndex="1"
                                    name="description"
                                    autoComplete="on"
                                    onChange={handleChange("description")}
                                    id="tempalte"
                                    className="form-control"
                                    defaultValue={description}
                                    ref={register2({ required: { value: true, message: "Beschreibung ist erforderlich" } })}
                                  ></textarea>
                                  <p className="text-danger error-text"  >{errors2?.description?.message}</p>
                                  </div>
                                </div>
                                

                              </div>
                              <div className="col-md-12 mb-3 ">
                                <div className="form-group d-flex align-items-center">
                                  <label className="">Briefing:</label>
                                  <div className="input-outer">
                                  <textarea
                                    rows="7"
                                    tabIndex="-1"
                                    name="briefing"
                                    autoComplete="on"
                                    onChange={handleChange("briefing")}
                                    id="tempalte"
                                    defaultValue={briefing}
                                    className="form-control"
                                    ref={register2({ required: { value: true, message: "Briefing ist erforderlich" } })}

                                  >
                                  </textarea>
                                  <p className="text-danger error-text"   >{errors2.briefing?.message}</p>
                                  </div>
                                </div>
                                

                              </div>
                              <div className="col-md-12 text-right mt-3">
                                <div className="save_btn">
                                  <button type="submit"  >
                                    Weiter
                                    </button>
                                </div>
                              </div>
                            </div>
                          </form>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Media">
                        <div id="Media" className="tabcontent  shadow">
                          <div className="shadow_legends">
                            <div className="container">
                              <div className="row">
                                {showMedia()}
                                <div className="col-md-12 mt-5">
                                  <h2>UPLOAD</h2>
                                </div>
                                <label htmlFor="story" className="col-md-6">
                                  <img
                                    width="100%"
                                    src={WEB_URL + "admin_assets/img/story.jpg"}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="story"
                                  name="stories[]"
                                  onChange={handelFiles}
                                  style={hideInput}
                                  multiple
                                  accept="image/*,video/mp4"
                                />
                                <label htmlFor="feed" className="col-md-6">
                                  <img
                                    width="100%"
                                    src={WEB_URL + "admin_assets/img/feed.jpg"}
                                  />
                                </label>
                                <input
                                  type="file"
                                  id="feed"
                                  name="feeds[]"
                                  onChange={handelFiles}
                                  style={hideInput}
                                  multiple
                                  accept="image/*,video/mp4"

                                />

                                <div className="col-md-12 text-right">
                                  <div className="save_btn">
                                    <a
                                      href="#"
                                      onClick={() => priceSection()}
                                    >
                                      Weiter
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="Pricetable">
                        <div
                          id="Pricetable"
                          className="tabcontent  shadow"
                        >
                          <div className="shadow_legends">
                            <div className="container">
                              <div className="row align-items-center">
                                <div className="col-md-12 mt-3">
                                  <form  key={2} onSubmit={handleSubmit(onSubmit)} >
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                      
                                            <label className="">
                                              Maximal verfügbare Downloads:
                                            </label>
                                            <div className="input-outer">
                                            <input
                                              type="number"
                                              min="1"

                                              autoComplete="on"
                                              name="max_availble_downloads"
                                              onChange={handleChange('max_availble_downloads')}
                                              className="form-control "
                                              aria-describedby="input"
                                              defaultValue={max_availble_downloads}
                                              ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}
                                              />
                                              <p className="text-danger error-text"  >{errors.max_availble_downloads?.message}</p>
                                              </div>
                                        </div>
                                       

                                        
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                          <label>User bekommen pro Download:</label>
                                          <div className="input-outer">
                                          <input
                                            type="number"
                                            step="0.01"
                                            autoComplete="on"
                                            name="user_per_download"
                                            onChange={handleChange(
                                              "user_per_download"
                                            )}
                                            className="form-control"
                                            aria-describedby="input"
                                            defaultValue={user_per_download}
                                            ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}

                                          />
                                          <p className="text-danger error-text"  >{errors.user_per_download?.message}</p>
                                          </div>
                                        </div>
                                        

                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                          <label>
                                            Sharify bekommt pro Download:
                                          </label>
                                          <div className="input-outer">
                                          <input
                                            type="number"
                                            step="0.01"
                                            autoComplete="on"
                                            name="sharify_get_download"
                                            onChange={handleChange(
                                              "sharify_get_download"
                                            )}
                                            className="form-control"
                                            aria-describedby="input"
                                            defaultValue={sharify_get_download}
                                            ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}

                                       

                                          />
                                          <p className="text-danger error-text"  >{errors.sharify_get_download?.message}</p>
                                          </div>
                                        </div>
                                        

                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                      
                                            <label className="">
                                              Android Playstore Link:
                                            </label>
                                            <div className="input-outer">
                                            <input
                                              type="text"
                                              name="linkAndroid"
                                              autoComplete="on"
                                              onChange={handleChange('linkAndroid')}
                                              className="form-control "
                                              aria-describedby="input"
                                              defaultValue={linkAndroid}
                                              ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}
                                              />
                                              <p className="text-danger error-text"  >{errors.linkAndroid?.message}</p>
                                              </div>
                                        </div>
                                       

                                        
                                      </div>  


                                         <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                      
                                            <label className="">
                                              iOS AppStore Link:
                                            </label>
                                            <div className="input-outer">
                                            <input
                                              type="text"
                                              name="linkIos"
                                              autoComplete="on"
                                              onChange={handleChange('linkIos')}
                                              className="form-control "
                                              aria-describedby="input"
                                              defaultValue={linkIos}
                                              ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}
                                              />
                                              <p className="text-danger error-text"  >{errors.linkIos?.message}</p>
                                              </div>
                                        </div>
                                       

                                        
                                      </div>       

                                      <div className="col-md-12 mt-5">
                                        <h2>Kampagnen-Laufzeit</h2>
                                      </div>

                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                          <label>Von:</label>
                                          <div className="input-outer">
                                          <input
                                            type="date"
                                            onChange={handleChange(
                                              "campaigns_from_date"
                                            )}
                                            name="campaigns_from_date"
                                            className="form-control"
                                            defaultValue={campaigns_from_date}
                                            ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}

                                          />
                                          <p className="text-danger error-text"  >{errors.campaigns_from_date?.message}</p>
                                          </div>
                                        </div>
                                        

                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group d-flex align-items-center">
                                          <label>Bis:</label>
                                          <div className="input-outer">
                                          <input
                                            type="date"
                                            name="campaigns_till_date"
                                            onChange={handleChange(
                                              "campaigns_till_date"
                                            )}
                                            className="form-control"
                                            min={campaigns_from_date}
                                            defaultValue={campaigns_till_date}
                                            ref={register({ required: { value: true, message: "Dieses Feld ist erforderlich" } })}

                                          />
                                          <p className="text-danger error-text"  >{errors.campaigns_till_date?.message}</p>
                                          </div>
                                        </div>
                                        

                                      </div>
                                      
                                    


                                      <div className="col-md-12 text-right mt-5">
                                        <div className="save_btn">
                                          <button type="submit" value="Submit">
                                            Speichern
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          
        
      </Modal>
     
    </Fragment>
  );
};

export default CampaingListing;
